/*
Sass convert
*/

/* BASE - Base styles, variables, mixins etc */

@import './styles/base/_variables.scss';
@import './styles/base/_mixins.scss';
@import './styles/base/_buttons.scss';

/* MODULES - Individual site components */

@import './styles/components/_typography.scss';
@import './styles/components/_defaults.scss';
@import './styles/components/_tables.scss';
@import './styles/components/_components.scss';

/******************************************
* THEME STYLESHEET
******************************************/
@import './styles/theme/theme.scss';
@import './custom-theme.scss';

/**NEW SHEETS**/
@import './styles/components/ui-elements.scss';
@import './styles/components/form-elements.scss';
@import './styles/components/growl_notification.scss';
@import './styles/components/layout_edits.scss';

/******************************************
* PAGE LAYOUT
******************************************/

/* header - see header.less */
@mixin inputstyle {
  &:focus, &:hover { outline: none; }
  &:hover { border-color: #fb8c00 !important; }
  &:focus { border-bottom-width: 2px; border-color: #fb8c00 !important; }
}
@mixin growlicon {
  background: white; border-radius: 20px; height: 36px; padding: 2px; position: absolute; left: 12px; display: block;
  top: 50%; -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%);  -ms-transform: translateY(-50%); transform: translateY(-50%);
}

@mixin md-soft-shadow (){
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
  -moz-box-shadow: 0 6px 12px rgba(0,0,0,.175);
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
}
.container { padding-left: 0; padding-right: 0; padding-bottom: $goldenEm*2; width: 100%; height: 100%; display: table; @include box-clearfix(); }
.sidebar {padding-top: $header-height+1;width: $sidebar-width;position: fixed;
  top: 0; left: 0; bottom: 0; z-index: 100; background: darken(desaturate($thermaticBlueHSL, 90%), 20%);
  &.show-notice { padding-top: $header-height + 28px; }
}
.main {margin: 0 auto;padding-top: $header-height;width: 100%;}
.main-private {margin: 0; padding-top: $header-height; padding-left: $sidebar-width + 20px; padding-right: 20px; width: 100%;
  //height: 100%;
  &.show-notice {
    padding-top: $header-height + 28px;
  }
}

/* footer - see footer.less */
/******************************************
* SHARED STYLES
******************************************/
a { cursor: pointer; }
body .ui-listbox .ui-listbox-item.ui-state-highlight { background-color: $primary_maincolor; }
.page-header { margin: 0 0 $goldenEm 0; padding: $goldenEm 0 $gutter; font-family: $header-font-family; font-weight: 400; border-bottom: 1px solid $gray20;
  &>* { margin-bottom: 0; }
  .breadcrumbs li { margin: 6px 0; font-size: 11px; display: inline-block; }
  .breadcrumbs a { font-weight: bold; text-decoration: none; color: darken($thermaticBlueHSL, 20%); }
  .breadcrumbs a:after{ padding: 0 0 0 6px; font-family: FontAwesome; content: "\f0da"; }
}
._switch_ { display: inline-block; }
body .md-inputfield label { left:0px; }
td { font-size: 14px !important; padding: 5px; }
th {font-size: 15px !important; }
.error { color: $red;
  &-sm { color: $red; font-size: 80%; word-break: break-all; }
}
.img-loading { font-family: FontAwesome; content: "\f03e"; font-size: 2em; }
.label-text { margin-bottom: 2px; font-weight: 400; color: $gray70; @include zeta; }
.label-orange { padding: 3px 6px 2px 6px; background: $primary_maincolor; font-size: 13px; border-radius: 50%; font-size: 12px; color: white; }
.label-default-thermatic { margin: 0 4px; background: $primary_maincolor; }

@media (max-width: 768px) {
  .sidebar { display: none; }
  .main-private { padding: 100px $goldenEm 0; }
  .main-private.show-notice { padding-top: 140px; }
}
.pull-left { float: left; }
.pull-right { float: right; }
.flex-container { display: flex; }
.flex-container>div { margin: 7px 5px 7px 5px; }
.epsilon, h5 { font-size: 13px; }
.zeta { color: red; font-style: normal; font-size: 13px;}
h2.page-title, .page-title { float: left; color: rgb(255, 255, 255); line-height: 18px; font-size: 18px; margin: 5px; font-weight: normal !important;
  color: #3e3e3e; font-size: 20px; margin: 5px; font-family: "RobotoMedium", "Trebuchet MS", Arial, Helvetica, sans-serif !important; }
.mat-page-title {color: rgb(255, 255, 255); line-height: 18px; font-size: 18px; margin: 5px; font-weight: normal !important;
color: #3e3e3e; font-size: 20px; margin: 5px; font-family: "RobotoMedium", "Trebuchet MS", Arial, Helvetica, sans-serif !important; }
.smallcase { text-transform: none !important;}
label.nlabel { display: block; font-weight: 500; font-size: 11px; color: rgba(0, 0, 0, 0.54); font-size: 10px; margin-bottom: 0px;}
table li.list-row, .table li.list-row, ul li.list-row, ol li.list-row {font-size: 14px; padding: 13px 0; margin-bottom: 0px; }
ul li:nth-child(odd).list-row{ /**background:whitesmoke;**/ background: white; }
ul li.list-row small { font-size: 90%; }
.row > * { margin-bottom: 0px; }
.loading {display:block; text-align:center; color:$primary_maincolor; font-style:italic; padding-top: 40px; }
.creatives { background: rgb(249, 249, 249); font-size: 13px; padding: 20px 10px; text-align: center; border: 1px dashed #dadada; border-radius: 10px; margin-bottom: 20px;
 background: #fff; padding: 0px; text-align: left; border: none;
}
.creatives .fa {font-size: 30px;}
.pri-lightbox { margin-bottom: 1.618em; margin-right: 0px; float: none;  }
.settings li { font-size: 13px; font-weight: normal !important;}
.e_msg { margin: 0px; top: 6px; position: relative; font-weight: bold; font-style: italic; }
.pri-pulldown .menu { right: 0; max-width: 320px; min-width: 280px; white-space: normal; background: white; }
a.ng2-smart-action-add-add { font-size: 14px; height: 36px; padding: 6px 16px;
  background: $primary_maincolor; color: white !important; font-weight: 500; display: inline-block;
  text-decoration: none; text-align:center; text-transform: uppercase;
  font-family: "RobotoMedium", "Trebuchet MS", Arial, Helvetica, sans-serif;
  @include btnshadow;
  &:hover {
    background: $primary_darkcolor; text-decoration: none !important;
  }
}
.pri-tooltip {
  i {
    &.fa-exclamation-circle, &.fa-info-circle { color: $primary_maincolor !important; font-size: 17px; }
  }
}
.ripple{position:relative;overflow:hidden;transform:translate3d(0,0,0);}
.ripple:after{content:"";display:block;position:absolute;width:100%;height:100%;top:0;left:0;pointer-events:none;background-image:radial-gradient(circle,#000 10%,transparent 10.01%);background-repeat:no-repeat;background-position:50%;transform:scale(10,10);opacity:0;transition:transform .5s,opacity 1s}
.ripple:active:after{transform:scale(0,0);opacity:.2;transition:0s;}

/** HELPERS **/
.nomargin { margin: 0px !important; }
.nopadding { padding: 0px !important; }
.nomargin-btm { margin-bottom: 0px !important; }
.nopadding-btm { padding-bottom: 0px !important; }
.nopadding-top { padding-top: 0px !important; }
.nopadding-right { padding-right: 0px !important; }
.pad-t-5 { padding-top: 5px; }
.pad-t-10 { padding-top: 10px !important; }
.pad-t-15 { padding-top: 15px !important; }
.pad-t-20 { padding-top: 20px; }
.pad-t-30 { padding-top: 30px; }
.pad-t-40 { padding-top: 40px; }
.pad-t-50 { padding-top: 50px; }
.pad-b-5 { padding-bottom: 5px; }
.pad-b-10 { padding-bottom: 10px !important; }
.pad-b-15 { padding-bottom: 15px !important; }
.pad-b-20 { padding-bottom: 20px; }
.pad-b-40 { padding-bottom: 40px; }
.mar-t-0 { margin-top: 0px; }
.mar-t-5 { margin-top: 5px; }
.mar-t-10 { margin-top: 10px; }
.mar-t-20 { margin-top: 20px; }
.mar-t-30 { margin-top: 30px; }
.mar-t-40 { margin-top: 40px; }
.mar-t-50 { margin-top: 50px; }
.mar-t-60 { margin-top: 60px; }
.mar-b-0 { margin-bottom: 0px; }
.mar-b-5 { margin-bottom: 5px; }
.mar-b-10 { margin-bottom: 10px; }
.mar-b-20 { margin-bottom: 20px; }
.mar-b-30 { margin-bottom: 30px; }
.mar-b-40 { margin-bottom: 40px; }
.mar-b-50 { margin-bottom: 50px; }
.mar-b-60 { margin-bottom: 60px; }

button.mat-icon-button { border-radius: 50% !important;
  &.mat-primary:hover { background: rgba(30, 136, 229, 0.12) !important; transition: 0.3s; }
  &.mat-warn:hover { background: #f9cbd4 !important; transition: 0.3s; }
  &.mat-accent:hover { background: #ffe7cb !important; transition: 0.3s; }
}
.ui-dropdown label.ui-dropdown-label:focus { background: red;}
p-tabView { width: 100%; }
.pri-lightbox .thumb { margin: 0 auto;}
body {
  .ajax-loader { color: $primary_lightcolor; }
}
.flatbtn { box-shadow: none !important;}

/**Buy component styles**/
.dettbl .ui-chkbox .ui-chkbox-box { border: 1px solid #d3d3d3 !important;  }
.dettbl .ui-chkbox .ui-chkbox-box.ui-state-active .fa { padding-left: 2px; }
.dettbl .ui-dropdown .ui-dropdown-trigger { margin-top: 3px; }
/**End style**/
.chartl{ /**overflow: scroll; overflow-y: hidden;**/  padding: 0px; display: inline-block; width: 100%;
  .chart-legend { width: 315px !important; display: block;
    div { width: 100% !important; }
  }
}
.eq_h { display: flex; /* equal height of the children */
  .headelement { padding: 20px 15px; width: 100%; border-bottom: 1px solid #d8d8d8; margin: 0px; display: inline-block;
    h2 { margin: 0px; }
  }
  .ui-tabview.ui-tabview-top > .ui-tabview-nav { border: none; }
}
.headelement .pri-tooltip { margin-top: 3px; }
.eq_h .col.tbldiv { flex: 1; /* additionally, equal width */ width: 60%; }
.eq_h .col.regdiv { flex: 1; /* additionally, equal width */ width: 40%; }
.eq_h .col.chartdiv { flex: 1; /* additionally, equal width */ width: 35%;
  .ui-accordion .ui-accordion-content { border: 1px solid #d8d8d8; }
  .ui-accordion .ui-accordion-header { background: white; }
  .ui-accordion .ui-accordion-header > a { color: #3d3e43; font-weight: 600; padding: 0.625em 1em; text-decoration: none;
    &:hover { background-color: $primary_maincolor; color: #ffffff; }
   }
   .ui-accordion .ui-accordion-header:not(.ui-state-active):not(.ui-state-disabled):hover { background-color: $primary_maincolor; }
  .ui-accordion .ui-accordion-header.ui-state-active { background-color: $primary_maincolor;
   a { color: #ffffff; }
  }
}
.md-inputfield span.ng-star-inserted { font-size: 11px; display: block; line-height: 12px; margin-top: 4px; color: red; }
.fa-exclamation-circle:before {
  content: "\f059";
  font-size: 20px;
}
.pri-tooltip > * { margin-bottom: 0px; }
.generated { border: 1px solid #ddd; padding: 0px; height: auto; background: white; }
.diagramDiv { margin: 0 auto; }

  /*** CAMPAIGN SUMMARY**/
  .calen_r{ margin-left: 5px;   }
  .campaign_summary {
    .material-icons {
      &.pause { color: $secondary_lightcolor;}
    }
    .actions {
      .new, .bulk {
        .ui-button-icon-only { background-color: $primary_maincolor !important;  border: 1px solid $primary_maincolor !important;
          &:hover, &:focus, &:active, &:hover[disabled], &:active:hover{
            background-color: $button_darkcolor !important; border: 1px solid $button_darkcolor !important;
          }
        }
      }
    }
  }
  html, body, .ui-treetable-scrollable-body  { height:100%;margin: 0;padding: 0; }


.cdk-overlay-container { z-index: 4000000000 !important;}
.query-builder .joinsarea .ui-table .ui-table-tbody > tr.joinstblbody.int.ui-state-highlight {
  background: #f9f9f9;
}
.fulldropdown {
  .ui-dropdown { width:100% !important; }
}
.notfound { text-align: center; display: block; width: 100%; font-size: 20px;}
.confirmdialog {
  .ui-dialog-footer { border-top: none !important;}
}
body {
  .ui-slider {
    .ui-slider-range { background: $primary_maincolor; }
    .ui-slider-handle { background: $primary_maincolor; width: 18px !important; height: 18px !important; }
  }
}
.mat_label_ .nlabel {font-size: 11px; color: rgba(0, 0, 0, 0.54); font-size: 10px;}
.mat-checkbox-label { font-weight: normal; }
.form_slider_ { margin-left: 10px; width: 98.5% !important; font-size: 11px; color: rgba(0, 0, 0, 0.54); font-size: 10px;
   color: rgba(0, 0, 0, 0.54); margin-bottom: 25px; font-weight: 600;
  .mat-slider-horizontal {width: 100% !important; margin-top:25px;}
}
.ui-slider-horizontal .ui-slider-handle {top: -8px !important;}
.p-container {
  display: flex; margin: 3px -17px 0px 0px; flex-wrap: nowrap; background-color: transparent;
}
.p-container > div { background-color: #f1f1f1; width: 50%; margin: 10px; width: 78.5%;
  &._l { background: transparent; padding-top: 10px; margin-bottom: 0px;}
  &._r {
    background: white; width: 20%; margin: 0px; border-left: 1px solid #d8d8d8; border-bottom: 1px solid #d8d8d8; position: relative;
    .card {width: 18.7%; padding: 7px;}
  }
}
.d-container {
  display: flex;
  align-items: stretch;
  background-color: #f1f1f1;
}
.d-container > div { background-color: white; width: 100px; margin: 5px; border: 1px solid #d8d8d8; margin-bottom: 0px; }
p-autocomplete { width: 100%;
  span.ui-autocomplete { width: 100%; }
}
.panes {
  height: 100%;
  width: 300%;
  display: flex;
  div.panes__ { flex: 1; padding-top: 13px;
    &.rightpane {  width: 90%; margin: 0 auto; position: relative; padding-top: 0px;
      .int_d .headelement { margin-left: 0px; width: 33.4%; left: unset; top: -3px; }
      .campaign_summary .campaign_footer{padding-bottom: 0px; margin-left: -8px; width: 33.4%; left: unset; }
      .dash_r_ {  margin-right: 0px !important;
        .query-builder { margin-left: 17px !important; width: 99% !important; }
       }
    }
  }
}
.campaign_footer {padding: 12px 40px; background: #fff; border: 1px solid #d8d8d8; border-top-color: rgb(216, 216, 216);
  border-top-style: solid; border-top-width: 1px; border-top: 1px solid #d8d8d8; border-bottom-right-radius: 3px; border-bottom-left-radius: 3px;
  position: fixed; bottom: 0; width: 100%; z-index: 3000;  margin: 0; left: 55px; text-align: left; box-shadow: 4px 0 6px #dedede;
}
.ui-multiselect .ui-multiselect-label { margin-bottom: 0px; }
.wizard { height: 100%;
  th.mat-header-cell:first-of-type {padding-left: 33px;}
  table{ background: white;
    thead th { border-left: none; }
    &>thead>tr>th {border-bottom: 1px solid #ddd; padding: 15px 8px; width: 15%;
    }
    td { padding: 7px 8px !important; border-left: none; }
    &>tbody>tr>td {
      &:first-child { padding: 7px 0px 7px 33px !important; }
    }
  }
}

/*STYLE TOOLTIP*/
.mat-tooltip.tooltipc { line-height: 14px; font-size: 13px; text-align: left; white-space: normal !important; } /*remove tooltip trucate*/
/**End**/
.mat-form-field-appearance-legacy .mat-form-field-underline { bottom: 0px; }
.mat-form-field-appearance-legacy .mat-form-field-wrapper { padding-bottom: 0px; }
.mat-form-field { width: 100%; }
.mat-form-field-infix {border-top: 0.2em solid transparent;}
.inputswitch .ng-pristine { display: inline-block; }
.ad_name_ .mat-form-field-appearance-legacy .mat-form-field-infix { padding: 1px 0px 0px 0px;}
.acct_name_ .mat-form-field-appearance-legacy .mat-form-field-infix { padding: 12px 0px 0px 0px; }
/**Radio buttons Group**/
.is_fullwidth{
  &.inputfield_, &.radiofield_, &.selectcomp_, .mat-radio-button { width: calc(100% - 20px); margin-left: 10px; margin-right: 10px; display: block; }
  .mat-radio-button { width:100%; padding: 7px 0px; }
}
.is_col_2, .is_col_3, .is_col_4, .is_col_5, .is_col_6 { margin-left: 10px; margin-right: 10px; display: inline-block; }
.is_col_2 {
  &.inputfield_, &.radiofield_, &.selectcomp_, .mat-radio-button { width:calc(50% - 20px);}
  .mat-radio-button { padding: 7px 0px; }
}
.is_col_3 {
  &.inputfield_, &.radiofield_, &.selectcomp_, .mat-radio-button { width:calc(33.3% - 20px);}
  .mat-radio-button { padding: 7px 0px; }
}
.is_col_4 {
  &.inputfield_, &.radiofield_, &.selectcomp_, .mat-radio-button { width:calc(25% - 20px);}
  .mat-radio-button { padding: 7px 0px; }
}
.is_col_5 {
  &.inputfield_, &.radiofield_, &.selectcomp_, .mat-radio-button { width:calc(20% - 20px);}
  .mat-radio-button { padding: 7px 0px; }
}
.is_col_6 {
  &.inputfield_, &.radiofield_, &.selectcomp_, .mat-radio-button { width:calc(16.6% - 20px);}
  .mat-radio-button { padding: 7px 0px; }
}
._elements {
  .mat-tab-body-wrapper { height: 422px; }
  .mat-list .mat-list-item .mat-list-item-content { display: block;
    p { font-size: 14px; width: 95%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
  }
}
.is_block_full { display: block !important; }
.haselement {
  .col-md-8 { width: 100%; }
  .col-md-4 { display: none; }
}
.i_tooltip { width: 90%; display: inline-block; }
.overlayposition .ui-overlaypanel { top: 43px !important; left: -141px !important; }
.time_picker_ {
  fieldset { border: none; }
  input{ margin: 10px 0px; font-size: 15px; padding: 0px; }
  .ngb-tp { width: 165px; margin: 0 auto; }
}
.changepassword {
  .mar-b-50 { margin-bottom: 65px; }
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {top: calc(100% - 0.166667em);}
.dstnurl .addiconbtn { top: 11px; margin-right: 5px; }
.body.flush {
  div.creativea_sec { /*border-top: 1px solid #d8d8d8;*/
    .mat-divider { padding-top: 30px; }
    &:first-child { border-top: none; padding-top: 0px;
      .mat-divider { display: none !important; }
    }
  }
}

.e-calendar.e-lib.e-keyboard {
  max-width: 325px;
  .e-day.e-title {
    background-color: #e3165b;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    color: white;
    margin-bottom: 10px;
  }
}

mat-dialog-content.dashboardChartComponent {
  max-height: initial;
}

mat-dialog-content.dashboardV2ChartComponent {
  max-height: initial;
}

.dashboardV2ChartComponent {
  mat-form-field {
    margin-bottom: 15px;
  }
  mat-form-field.dsiplayColumnNameContainer {
    margin-bottom: 30px;
  }
  mat-form-field.bottomSpacer {
    margin-bottom: 35px;
  }
}

.dashboardEngineContainer .filterContainer .accountComparisonContainer .mat-form-field-wrapper {
  margin-bottom: 0px !important;
}

@media screen and (min-width:1900px) {
  .cam_tbl .ui-treetable-scrollable-header-box, .cam_tbl .ui-treetable-scrollable-body-table { width: 100%; }
}

@media screen and (max-width:990px) {
  .netbudget { margin-top: 0px; }
}
@media screen and (max-width:870px) {
  .chartl{
    .chart-legend { width: 100% !important; }
  }
}

@media screen and (max-width:770px) {
.eq_h .headelement h2 { font-size: 18px; }
}

@media screen and (max-width:700px) {
.newbtn, .editbtn, .deletebtn, .closebtn, .savebtn, .viewbtn, .downloadbtn, .ui-widget button { font-size: 13px !important; padding: 8px 7px; }
body .ui-tabview .ui-tabview-nav > li .ui-tabview-title {font-size: 12px;}
}
.ui-treetable .ui-treetable-tbody .row-delete, .joinstblbody .row-delete {
  background-color: #ffe2e2 !important;
}
.mat-dialog-content {overflow: hidden !important;}
.mat-calendar-body-cell-content { width: 30px; height: 30px;}
.mat-calendar-body-cell {padding-top: 6.14286% !important; }

.center_c {width: 55%;margin: 0 auto; padding-left: 20px;padding-right: 20px;}
.ui-panel.ui-widget { box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12); }
.reset_pword {
  .mar-b-50 { margin-bottom: 70px !important; }
}

.beamer_defaultBeamerSelector.top-right {
  top: 6px !important;
  right: 140px !important;
  bottom: initial;
  height: 35px !important;
  width: 35px !important;
  box-shadow: none !important;
  .beamer_icon.active {
    right: -14px !important
  }
}

.dashboardEngineMatOptionIcon {
  margin-right: 0px !important;
}

.dashboardLeaderboardContainer {
  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    margin-bottom: 10px !important;
  }
  .mat-paginator-navigation-previous, .mat-paginator-navigation-next {
    margin-top: -5px
  }
}

.locationTableActionsContainer .mat-form-field-wrapper {
  margin-bottom: 0px !important;
}

.locationTableActionsContainer .mat-select-trigger {
  top: 4px;
}

.multiSelectIconOnly {
  .mat-form-field-subscript-wrapper, .mat-form-field-underline {
    display: none;
  }
  .mat-select-arrow {
    color: white;
  }
}

.locationsComponentTable {
  .mat-column-LocationName, .mat-column-LocationState {
    app-form-select {
      mat-form-field {
        margin-top: 7px;
        margin-bottom: 6px;
      }
    }
  }
  .mat-column-LocationWebsiteUrl {
    .mat-form-field-wrapper {
      padding-bottom: 0px;
    }
  }
  .mat-cell {
    .mat-form-field-wrapper {
      margin-bottom: 11px !important;
    }
    app-form-select {
      .mat-form-field {
        width: 68%;
        .mat-form-field-wrapper {
          margin-bottom: 5px !important;
        }
      }
    }
  }
  .mat-paginator-container {
    justify-content: flex-start;
  }
  .mat-paginator-page-size {
    align-items: center;
    .mat-form-field-wrapper {
      margin-bottom: 6px !important;
    }
  }
}

.uploadLocationsContent {
  .mat-horizontal-stepper-wrapper {
    height: 100%;
  }
  .mat-horizontal-content-container {
    height: calc(100% - 72px);
    .mat-horizontal-stepper-content {
      height: 100%;
      form {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
    .mat-horizontal-stepper-content:nth-child(2) {
      margin-top: -702px;
    }
    .mat-horizontal-stepper-content:nth-child(3) {
      margin-top: -702px;
    }
  }
}

.uploadLocationTableContainer {
  .mat-paginator-page-size {
    align-items: center;
    .mat-form-field-wrapper {
      margin-bottom: 6px !important;
    }
  }
}

.updateTagsContainer {
  mat-dialog-content {
    flex: 1;
  }
}

.centerTextToolTip {
  text-align: center;
}

.subscriptionContainer {
  .btnGroupContainer {
    .mat-button-wrapper {
      font-size: 12px;
    }
  }
  .mat-divider-horizontal {
    position: relative !important;
    margin: 10px 0px;
  }
}

.mediaBudgetContainer {
  .mat-form-field-wrapper {
    margin-bottom: 0px !important;
  }
  .mat-form-field-prefix {
    position: absolute;
    top: 9px;
    left: -5px;
  }
  .mediaBudgetInputContainer .mat-form-field-prefix {
    top: 10px;
  }
}

.dashboardConfigMainWrapperContainer {
  .mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
    opacity: 1;
    color: initial;
  }
}

.fitFilteringContainer {
  .mat-form-field-wrapper {
    margin-bottom: 0px !important;
  }
  .dateSelect {
    .mat-form-field-infix {
      padding-bottom: 1px;
    }
  }
}

.fitSegmentedGaugeChartContainer {
  font-family: Roboto;
  .fitScoreIndicator {
    font-size: 60px;
    font-weight: 600;
    text-anchor: middle;
  }
  .fitScoreBucket, .fitScoreChange, .lastUpdated, .fitVersion {
    text-anchor: middle;
  }
  .fitScoreChange {
    font-size: 20px;
    font-weight: 700;
  }
  .fitScoreBucket {
    font-size: 18px;
  }
  .lastUpdated, .fitVersion {
    font-size: 12px;
    fill: #6A6A6A;
  }
  .fitVersion {
    text-decoration: underline;
  }
}

.metricWeightContainer, .metricThresholdContainer {
  .mat-form-field-wrapper {
    margin-bottom: 0px !important;
    height: 100%;
    .mat-form-field-flex, .mat-form-field-infix, input {
      height: 100%;
    }
    input {
      text-align: center;
      font-size: 24px;
      font-family: 'RobotoMedium';
    }
  }
  .rangeTypeContainer {
    .mat-form-field {
      height: 100%;
      .mat-form-field-infix {
        display: flex;
        align-items: center;
      }
    }
    .mat-form-field-underline {
      display: none;
    }
  }
}

.integrationsSetupComponentContainer {
  .mat-form-field-wrapper {
    padding-top: 8px;
  }
  .mat-form-field-infix {
    padding-bottom: 0px !important;
  }
}

.dataTableComponentContainer {
  .mat-paginator {
    .mat-form-field-wrapper {
      margin-bottom: 9px !important;
    }
  }
  .mat-form-field-wrapper {
    margin-bottom: 0px !important;
  }
}

.dashboardPageSelector .material-icons {
  height: 16px;
  line-height: 12px;
}

.dashboardPageSelector .mat-button.containsActivePage {
  background-color: rgba(30, 136, 229, .6);
  border-radius: 15px;
  color: white;
  font-weight: 600;
}

.mat-menu-item.dashboardPageIsActive {
  background-color: #1e88e5;
  color: white;
  font-weight: 600;
  &.mat-menu-item:hover:not([disabled]) {
    background-color: rgba(30, 136, 229, .8)
  }
}

.legendItems.customScroll {
  scrollbar-color: #1e88e5 #e4e4e4;
  scrollbar-width: thin;
}

.legendItems.customScroll::-webkit-scrollbar {
  width: 10px;
}

.legendItems.customScroll::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.legendItems.customScroll::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 2px solid transparent;
  background-clip: content-box;
  background-color: #1e88e5;
}

.leaderboardV2Container {
  table thead {
    display: none;
  };
  td {
    border-bottom-width: 0px !important;
  };
  .cdk-column-percentChangeInValue {
    font-size: 12px !important;
  };
  .mat-paginator-container {
    padding: 0px !important;
    justify-content: space-evenly;
  }
  mat-paginator {
    .mat-paginator-page-size-select {
      margin-top: 0px !important;
      .mat-form-field-wrapper {
        margin-bottom: 0px !important;
      };
    };
  };
  .mat-paginator-range-label {
    margin: 0px;
    margin-right: 5px;
  }
}

.globalFilterContainer {
  .mat-form-field-wrapper {
    margin-bottom: 0px !important;
    padding-bottom: 0px
  }
  .locationFilteringContainer {
    .mat-form-field-appearance-fill .mat-form-field-flex {
      border-radius:8px;
      background-color: rgba(125, 63, 140, 0.26) !important;
    }
    .mat-form-field-flex {
      width: calc(65px + 15em);
    }
    .mat-form-field-infix {
      display: flex;
      align-items: center;
      width: fit-content !important;
    }
    input {
      width: 15em;
    }
    .mat-form-field-underline {
      display: none;
    }
  }
  .rightFilters {
    .mat-form-field-appearance-outline .mat-form-field-label-wrapper  {
      top: -5px !important;
      left: -1px !important
    }
    .mat-form-field .mat-form-field-label {
      color: #7D3F8C !important;
    }
    .mat-form-field-appearance-outline {
      .mat-form-field-outline-start, .mat-form-field-outline-end {
        border-color: #7D3F8C !important;
      }
      .mat-form-field-outline-gap {
        border-bottom-color: #7D3F8C !important;
      }
      .mat-select-arrow-wrapper {
        transform: none !important;
      }
    }
    .mat-form-field-appearance-outline.mat-focused.mat-primary .mat-select-arrow {
      color: #7D3F8C !important;
    }
    .mat-select-value {
      color: #7D3F8C !important;;
    }
  }
  .globalFilterDatePickerContainer {
    mat-form-field {
      margin: 0px 20px 0px 15px !important;
      .mat-form-field-wrapper {
        input {
          cursor: pointer;
        }
      }
    }
  }
}

.mat-primary .globalFilterSelectOptions.mat-option.mat-selected:not(.mat-option-disabled) {
  color: #7D3F8C !important;
}

.mtx-drawer-content-wrapper:has(.globalFilterRightSheetContainer) {
  padding: 0px !important;
}

.globalFilterWrapper {
  .mat-progress-spinner circle, .mat-spinner circle {
    stroke: rgb(170, 170, 170);
  }
}

.globalFilterRightSheetContainer {
  .mat-progress-spinner circle, .mat-spinner circle {
    stroke: #7d3f8c;
  }
}

.globalFilterRightSheetContainer {
  mat-expansion-panel-header {
    .mat-content {
      flex: unset;
      margin-right: 15px;
      width: 100%;
    }
  }
  .mat-expansion-panel-content {
    p {
      font-size: 14px;
    }
  }
  .mat-expansion-panel-body {
    padding: 0 15px 15px;
  }
  .filteringInput {
    .mat-form-field-wrapper {
      margin-bottom: 0px !important;
    }
  }
}

.mtx-slider:not(.mtx-slider-disabled).cdk-focused.mtx-slider-thumb-label-showing .mtx-slider-thumb {
  transform: scale(1) !important;
}
.mtx-slider {
  .mtx-slider-track-fill {
    background-color: #7D3F8C;
  }
  .mtx-slider-thumb {
    background-color: white;
  }
  .mtx-slider-thumb-label {
    transform: translateY(5px) rotate(45deg) !important;
    background-color: transparent;
    .mtx-slider-thumb-label-text {
      opacity: 1 !important;
      color: #7D3F8C;
    }
  }
}

.fitFranchiseeScoreContainer, .helperBoxContainer {
  .mat-tab-label {
    flex: 1;
  }
  .mat-tab-label-active {
    opacity: 1 !important;
  }
  .mat-tab-label-active .mat-tab-label-content {
    color: #1e88e5 !important;
  }
  .mat-tab-body-wrapper {
    height: 100%;
    .mat-tab-body-content {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
  }
}

.fitSetupMetricDialog {
  .mat-dialog-container > ng-component:first-child {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.dashboardCompareRightSheetContainer {
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $comparison_maincolor !important;
  }
  .mat-ripple-element  {
    background: #7d3f8c8e !important;
 }
}

.calendarPickerDialog {
  .mat-calendar-body {
    td {
      border-color: transparent !important;
    }
  }
  mat-multi-year-view, mat-year-view {
    .mat-calendar-body-cell-content {
      width: 90% !important;
    }
  }
}

.reportDataIssue {
  color: grey !important;
}
.reportDataIssue:hover {
  color: #fb8c00 !important;
}
