/*
	VARIABLES
*/
// Golden ratio, which makes just about anything look lovely
$golden: 1.618;
$goldenEm: $golden + 0em;
$site-max-width: 960px;
$gutter: 16px;

$header-height: 49px;
$sidebar-width: 220px;

$footer-height: 38px;

/* BG Colors */


/* Font */
$base-font-size: 14px;
$base-font-family: "RobotoRegular", "Open Sans", Arial, serif;
$header-font-size: 16px;
$header-font-family: "Titillium Web", Arial, serif;

/*
B/W Color Scale
*/
$black: hsl(0, 0%, 0%);
$gray90: hsl(0, 0%, 10%);
$gray80: hsl(0, 0%, 20%);
$gray70: hsl(0, 0%, 30%);
$gray60: hsl(0, 0%, 40%);
$gray50: hsl(0, 0%, 50%);
$gray40: hsl(0, 0%, 60%);
$gray30: hsl(0, 0%, 70%);
$gray20: hsl(0, 0%, 80%);
$gray10: hsl(0, 0%, 90%);
$gray05: hsl(0, 0%, 95%);
$gray03: hsl(0, 0%, 97%);
$gray01: hsl(0, 0%, 99%);
$white: hsl(0, 0%, 100%);

$red: #DB2828;

$thermaticBlue: #06c9dd;
$thermaticOrange: #fd6f00;
$thermaticYellow: #ffcd05;

$thermaticBlueHSL: hsl(186,95%, 45%);
$thermaticOrangeHSL: hsl(26,100%, 50%);
$thermaticYellowHSL: hsl(48, 100%, 51%);

$thermaticBlueRGB: rgb(6,201,221);
$thermaticOrangeRGB: rgb(253, 111, 0);
$thermaticYellowRGB: rgb(255, 205, 5);

$yui-panel-bg: $gray10;


$xLoPoint: 320px;
$loPoint: 768px;
$midPoint: 992px;
$loPoint: 1200px;

$breakpoint-xsmall-screen: 320px;
$breakpoint-small-screen: 768px;
$breakpoint-mid-screen: 996px;
$breakpoint-large-screen: 1200px;
