.inputswitch {
    label { font-weight: 500; font-size: 13px; margin-right: 10px; position: relative; top: -3px;
      &.nth2 { margin-right: 0px; margin-left: 10px; }
    }
  }

.ui-inputtext { width: 100%;
  &:hover, &:enabled:hover { border-color: $secondary_maincolor !important; }
  &:focus { box-shadow: none !important; border-color: $secondary_maincolor !important;  }
 }
.ui-chkbox .ui-chkbox-box:hover { border: 2px solid $secondary_maincolor; }
.ui-dropdown:not(.ui-state-disabled):hover { border-color: $secondary_maincolor; background: white; }
.ui-datepicker.ui-widget .ui-datepicker-calendar thead th { background-color: #793903 !important; padding: 8px !important; font-size: 13px !important; font-weight: normal; }
.ui-datepicker .ui-datepicker-title { color: white; }
body .ui-calendar .ui-datepicker-trigger .fa { color: #757575 !important; }
body .ui-calendar .ui-datepicker-trigger { background: transparent !important; }
.ui-calendar { width: 100%; }
.ui-chkbox-label { margin: 0px; font-weight: normal; }
.ui-g.form-group > div { padding: 18px 16px; }
.ui-datepicker.ui-widget .ui-datepicker-calendar td {
  padding: 2px 6px !important;
  a { padding: 3px !important; height: 2.050em; width: 2.050em; font-size: 12px; }
}
.dropdown-menu .divider {margin: 2px 0;}
input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill { background: white; }
body .ui-inputswitch.ui-inputswitch-checked, body .creatdtsarea .ng2-smart-row.selected .ui-inputswitch.ui-inputswitch-checked, .table-inputswitch-checked .ui-inputswitch { background-color: #fac187; border-color: #fac187; }
.ng2-smart-row.selected { background: white !important; }
body .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-handle, body .table-inputswitch-checked .ui-inputswitch-handle { background-color: $secondary_maincolor; border: 1px solid #FB8C00; }
.ui-inputswitch .ui-inputswitch-checked .ui-inputswitch-handle { background-color: $secondary_maincolor; border: 1px solid #FB8C00; }
.ui-dropdown.ui-state-focus{box-shadow: 0px 0px 2px #fff !important; }
input.readonly { color: #bebebe; cursor: auto; }
.ui-picklist .ui-picklist-buttons-cell .ui-button.ui-button-icon-only .fa { transform: inherit; }
.ui-radiobutton-icon.fa-circle:before {color: $secondary_maincolor;}
.ui-radiobutton .ui-radiobutton-box.ui-state-active { background-color: #FB8C00 !important; }
.ui-dropdown-panel, .ui-datepicker { z-index: /**2000003**/2000000003 !important; }