@use 'sass:math';

.pri-loader-dots {
  display: inline-block;

  span {
    margin: 0 2px;
    width: 12px;
    height: 12px;
    display: inline-block;
    background: $black;
    border-radius: 50%;
    transform: scale(0.25);
  }

  span:nth-child(1) {
    @include animate(fadePulse, 2s, 0.1s, infinite, ease-out);
  }
  span:nth-child(2) {
    @include animate(fadePulse, 2s, 0.3s, infinite, ease-out);
  }
  span:nth-child(3) {
    @include animate(fadePulse, 2s, 0.5s, infinite, ease-out);
  }
  span:nth-child(4) {
    @include animate(fadePulse, 2s, 0.7s, infinite, ease-out);
  }
  span:nth-child(5) {
    @include animate(fadePulse, 2s, 0.9s, infinite, ease-out);
  }

  @keyframes fadePulse {
    50% {
      opacity: 0.1;
      transform: scale(1);
    }

    100% {
      opacity: 0.5;
    }
  }
}

.yui-modal {
  width: 100%;
  height: 100%;
  display: none;

  &.open {
    display: block;
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.5;
    background: black;
    z-index: 999;
  }

  .modal-content {
    margin: 0 auto;
    padding: 0;
    background: white;
    border: 1px solid $gray10;
    border-radius: 8px;
    @include md-box-shadow();
    position: fixed;
    z-index: 1000;
  }

  .heading {
    padding: math.div($goldenEm,2) $goldenEm;
    border-bottom: 1px solid $gray10;
    @include rowflush();
  }

  .body {
    padding: $goldenEm;
    @include rowflush();
  }
}

@mixin yui-panel() {
  .yui-panel {
    border: none;
  }
}

.yui-panel-collapse {
  @include yui-panel;

  .heading {
    border: none;
    background: $gray03;

    h4 {
      font-weight: 600;
    }
  }

  .body {
    display: none;
    border-top: 1px solid $gray10;
  }

  &.open {
    .heading {
      background: $white;
    }
    .body {
      display: block;
    }
  }
}

.pri-dropdown {
  $dropdownHeight: 34px;
  background: transparent;
  position: relative;

  .active {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .dropdown-select {
    height: $dropdownHeight;
    line-height: $dropdownHeight;
    border: 1px solid $gray20;
    position: relative;
    border-radius: 2px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);

    .select-name {
      width: 90%;
      color: $black;
      display: inline-block;
      font-weight: bolder;
      text-indent: 15px;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .dropdown-select:after {
    font-family: FontAwesome;
    content: '\f0d7';
    display: inline-block;
    position: absolute;
    right: 0.5em;
  }

  .dropdown-list {
    width: 100%;
    background: white;
    white-space: nowrap;
    overflow: hidden;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    border: 1px solid $gray20;
    border-top: none;
    position: absolute;
    z-index: 1000;

    li {
      margin: 0;

      span {
        padding-left: 15px;
        display: block;
        color: $black;
        height: $dropdownHeight;
        line-height: $dropdownHeight;
      }

      span.hover:hover {
        background: #eeeeee;
        cursor: pointer;
      }
    }

    .sub li {
      text-indent: 15px;
    }
  }

  &.error {
    .dropdown-select {
      border-color: red;
    }
  }

  &.muted {
    .active {
      box-shadow: none;
    }

    .dropdown-select {
      background: transparent;
      border: 1px dashed $gray40;
      border-width: 0 0 1px 0;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;

      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }
    }

    &.error {
      .dropdown-select {
        border-color: red;
      }
    }
  }

  &.centered {
    text-align: center;
  }

  &.disabled {
    cursor: not-allowed;
    color: $gray40;

    .dropdown-select {
      background: rgba(0, 0, 0, 0.05);
    }
    .select-name {
      color: $gray40;
    }
  }
}

.pri-livesearch {
  $dropdownHeight: 34px;
  background: transparent;
  position: relative;

  .backdrop {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 990;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .dropdown-select {
    height: $dropdownHeight;
    border: 1px solid $gray20;
    position: relative;
    border-radius: 2px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);

    &.active {
      z-index: 999;
    }

    .select-name {
      padding-left: $gutter;
      width: 90%;
      color: $black;
      display: inline-block;
      font-weight: 600;
      line-height: $dropdownHeight;
      overflow: hidden;
      white-space: nowrap;
    }

    input {
      width: 100%;
      height: 100%;
      padding-left: $gutter;
      padding-right: 20px;
      position: relative;
      border: none;
    }
  }
  .dropdown-select:after {
    font-family: FontAwesome;
    content: '\f0d7';
    display: inline-block;
    position: absolute;
    top: 20%;
    right: 0.5em;
  }

  .dropdown-list {
    display: none;
    width: 100%;
    max-height: 204px;
    background: white;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    border: 1px solid $gray20;
    border-top: none;
    position: absolute;
    z-index: 1000;

    li {
      margin: 0;

      span {
        padding-left: 15px;
        display: block;
        color: $black;
        height: $dropdownHeight;
        line-height: $dropdownHeight;
      }

      span.hover:hover {
        background: #eeeeee;
        cursor: pointer;
      }

      &:hover {
        background: #eeeeee;
        cursor: pointer;
      }

      &.active {
        background: #eeeeee;
      }
    }

    .sub li {
      text-indent: 15px;
    }

    &.open {
      display: block;
    }
  }

  &.error {
    .dropdown-select {
      border-color: red;
    }
  }

  &.muted {
    .active {
      box-shadow: none;
    }

    .dropdown-select {
      background: transparent;
      border: 1px dashed $gray40;
      border-width: 0 0 1px 0;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;

      input {
        background: transparent;
        box-shadow: none;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }
    }

    &.error {
      .dropdown-select {
        border-color: red;
      }
    }
  }

  &.centered {
    text-align: center;
  }

  &.disabled {
    cursor: not-allowed;
    color: $gray40;

    .dropdown-select {
      background: rgba(0, 0, 0, 0.05);
    }
    .select-name {
      color: $gray40;
    }
  }
}

.pri-toggle {
  display: inline-block;
  white-space: nowrap;
  min-width: 120px;

  input[type='radio'] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }

  input[type='radio'] + label {
    display: inline-block;
    padding: 4px;
    color: $black;
    background: $gray01;
    border: 1px solid $gray40;
  }

  label {
    padding: math.div($gutter, 2) $gutter;
    float: left;
    font-size: 11px;
    font-weight: 400;
    color: $gray50;
    background: $gray20;
    border: 1px solid $gray30;
    border-left: none;
    cursor: pointer;

    &:first-child {
      border-left: 1px solid $gray30;
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }

  input[type='radio']:checked {
    background: $gray30;
  }

  label.active {
    color: $gray70;
    font-weight: 600;
    background: $gray01;
    cursor: default;
  }

  &.shadowed {
    label {
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    }
  }

  &.toggle-blue {
    label {
      background: #d4dedf;
    }

    label.active {
      color: darken($thermaticBlueHSL, 10%);
      background: $white;
    }
  }
}

.pri-switch {
  display: inline-block;
  line-height: normal;

  input[type='checkbox'] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }

  label {
    width: 44px;
    height: 18px;
    line-height: 16px;
    display: inline-block;
    //float: left;
    font-size: 10px;
    font-weight: 500;
    color: $gray50;
    position: relative;
    background: $gray20;
    border-radius: 10px;
    border: 1px solid $gray20;
    border-left: none;
    cursor: pointer;

    &:after {
      content: '';
      display: inline-block;
      width: 18px;
      height: 18px;
      position: absolute;
      top: -1px;
      left: 0;
      right: 0;
      bottom: 0;
      background: $white;
      border: 1px solid $gray20;
      border-radius: 100%;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    &:before {
      content: 'OFF';
      padding-left: 20px;
    }
  }

  label.active {
    background: $gray50;

    &:after {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    &:before {
      content: 'ON';
      padding-left: 5px;
      color: $white;
      font-weight: bold;
    }
  }

  &.disabled {
    label {
      cursor: not-allowed;
    }
  }
}

.pri-lightbox {
  margin-right: $goldenEm;
  padding: 4px;
  color: $black;
  text-align: center;
  float: left;
  position: relative;
  overflow: hidden;
  background: $white;
  @include md-box-shadow();

  .flag {
    width: 0px;
    height: 0px;
    color: $white;
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    background-color: transparent !important;
    border-top: 0 solid transparent;
    border-right: 2.5em solid transparent;
    border-bottom: 2.5em solid transparent;
    border-left: 0 solid transparent;
    border-right-color: $red;

    i {
      padding-left: 17px;
    }
  }

  .thumb {
    width: 240px;
    height: 200px;
  }

  .delete {
    position: absolute;
    display: none;

    .menu {
      display: block;
      padding: $goldenEm;
      width: 100%;
      height: 100%;
      background: $gray05;
    }

    button {
      margin-bottom: math.div($gutter, 2);
    }

    &.open {
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 20;
    }
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  .info {
    padding: 4px;
    margin: 0 -4px;
    width: 100%;
    height: 130px;
    color: $black;
    position: absolute;
    top: 100%;
    background: rgba(255, 255, 255, 0.9);
    -webkit-transition: 0.4s;
    transition: 0.4s;

    p {
      margin: 5px 4px;
      text-align: left;

      &.name {
        margin-bottom: 4px;
        font-weight: 600;
      }

      &.date,
      &.size {
        font-size: 12px;
        line-height: 14px;
      }
    }
    input[type='text'] {
      width: 75%;
    }
    .pri-tooltip p.content {
      right: 0;
    }

    li {
      padding-top: 4px;
      display: inline-block;
      width: 68%;
    }
    li:first-child {
      width: 30%;
    }
  }
  .info:before {
    font-family: FontAwesome;
    content: '\f077';
    width: 36px;
    height: 30px;
    display: inline-block;
    position: absolute;
    top: -30px;
    right: 0;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 4px 0 0 0;
  }

  &.inactive {
    background: $gray05;

    .thumb:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.2);
    }
  }

  &.warning {
    .flag {
      display: inline-block;
    }
  }

  &:hover {
    .thumb {
      cursor: pointer;
    }

    .info {
      transform: translateY(-130px);
    }
  }
}

.pri-pulldown {
  display: inline-block;
  position: relative;

  .backdrop {
    display: none;
    background: white;
    opacity: 0.1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
  }

  .menu {
    display: none;
    margin-top: math.div($gutter, 2);
    padding: $goldenEm;
    width: 100%;
    line-height: normal;
    background: $gray05;
    position: absolute;
    z-index: 100;
    @include md-soft-shadow();
  }

  &.open {
    .backdrop {
      display: block;
    }
    .menu {
      display: block;
    }
  }

  &.rt {
    .menu {
      right: 0;
    }
  }

  &.lt {
    .menu {
      left: 0;
    }
  }
}

.pri-carousel {
  width: 100%;
  overflow-x: auto;

  .arrow {
    height: 100px;
    display: inline-block;
  }

  ul {
    white-space: nowrap;
  }

  li {
    margin: 0 $gutter;
    display: inline-block;

    .loader {
      margin: -4px;
      display: none;
      width: 100%;
      height: 100%;
      position: absolute;
      background: transparent url('/assets/img/loading.gif') center top
        no-repeat;
      opacity: 0.2;
    }

    &.loading {
      .loader {
        display: block;
      }

      .thumb:after {
        display: none;
      }
    }
  }

  .thumb {
    padding: 4px;
    background: $white;
    max-width: 200px;
    min-width: 100px;
    height: 100px;
    position: relative;
    text-align: center;
    cursor: pointer;
    @include md-box-shadow();

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(178, 182, 183, 0.6);
    }
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  .info {
    margin: 0;
    padding: 0;
  }

  p {
    margin: math.div($gutter, 2) 0;
    line-height: 16px;
  }

  .index {
    padding: 0 4px;
    display: inline-block;
    font-size: 10px;
    color: $black;
    background: $thermaticOrange;
    border-radius: 2px;
  }

  span {
    font-weight: 600;
    font-size: 12px;
  }

  .active {
    .thumb:after {
      display: none;
    }
  }

  .thumb:hover:after {
    display: none;
  }
}

.yui-popover {
  display: inline-block;
  position: relative;

  .content {
    display: block;
    padding: math.div($goldenEm,2);
    min-width: 200px;
    position: absolute;
    line-height: normal;
    top: -100%;
    z-index: 99;
    background: $white;
    border: 1px solid $gray10;
    border-radius: 4px;
    @include md-soft-shadow();
  }
}

.pri-tooltip {
  display: inline-block;
  position: relative;
  vertical-align: top;
  & > * {
    padding: 0 4px;
    display: inline-block;
  }

  .content {
    display: none;
    font-size: 12px;
    padding: math.div($goldenEm,2);
    min-width: 200px;
    color: $black;
    line-height: normal;
    white-space: normal;
    position: absolute;
    z-index: 99;
    background: $white;
    border: 1px solid $gray10;
    border-radius: 4px;
    @include md-soft-shadow();

    & > strong {
      display: block;
    }
  }

  &:hover {
    .content {
      display: block;
    }
  }

  &.error {
    color: $red;

    .content {
      color: $red;
    }
  }

  &.rt {
    .content {
      right: 0;
    }
  }

  &.lt {
    .content {
      left: 0;
    }
  }

  &.top {
    .content {
      top: 0;
    }
  }

  &.bottom {
    .content {
      bottom: 0;
    }
  }
}

.yui-edit {
  padding-right: 25px;
  display: inline-block;
  width: 100%;
  min-width: 122px;
  white-space: nowrap;
  position: relative;

  input[type='text'] {
    width: 100%;
    min-width: 100px;
  }

  button {
    width: 10%;
    max-width: 20px;
    min-width: 20px;
    position: absolute;
    bottom: 0%;
  }
}

.yui-filterstatus {
  display: inline-block;

  .filter-names {
    font-size: 12px;
    font-weight: 600;
    color: $gray70;
  }

  .label {
    background-color: darken($thermaticBlueHSL, 10%);
  }
}

.yui-slide-panel {
  $slide-panel-height: 36px;

  width: 350px;
  background: rgba(244, 244, 244, 0.95);
  position: absolute;
  right: -350px;
  top: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  .tab {
    width: 24px;
    height: 100%;
    color: $gray60;
    background: rgba(227, 227, 227, 0.2);
    position: absolute;
    right: 100%;
    border-radius: 2px 0 0 2px;
    @include box-shadow(-2px, -1px, 4px, rgba(0, 0, 0, 0.1));

    span {
      display: block;
      font-size: 14px;
      height: $slide-panel-height;
      line-height: $slide-panel-height;
      text-align: center;
      opacity: 0.25;
    }

    .exit {
      display: none;
      transform: rotate(90deg);
      white-space: nowrap;
      position: absolute;
    }
  }

  li.slide-bar {
    border-top: 1px solid $gray30;

    &:hover {
      cursor: pointer;
    }
  }

  li.slide-bar:first-child {
    border-top: none;
  }

  .slide-title {
    margin: 0;
    padding: 0 0 0 $gutter;
    height: $slide-panel-height;
    line-height: $slide-panel-height;
    background: rgba(228, 233, 233, 0.95);
  }

  .slide-menu {
    display: none;
    height: 0;
    padding: $gutter;
    border-top: 1px solid $gray30;

    &.open {
      display: block;
      height: auto;
      @include box-clearfix();
    }
  }

  .slide-footer {
    padding: 4px;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 10;
    background: $gray10;
  }

  &.open {
    transform: translateX(-100%);

    .tab {
      .exit {
        display: block;
        cursor: pointer;
      }

      &:hover .exit {
        opacity: 0.8;
      }
    }
  }

  &.static {
    width: 100%;
    position: static;
    right: 0;

    .slide-footer {
      background: $white;
      border-top: 1px solid $gray30;
      position: static;
    }
  }
}

.yui-combobox {
  position: relative;
  border: 1px solid $gray20;

  .combo-list {
    min-height: 36px;
    max-height: 180px;
    overflow-y: scroll;
  }

  li {
    margin: 0;
    padding: 0 math.div($gutter, 2);
    height: 36px;
    color: $gray70;
    font-size: 11px;
    line-height: 36px;
    white-space: nowrap;
    background: $gray10;
    border-top: 1px solid $gray40;

    i {
      padding: 0 math.div($gutter, 2);
    }

    &:first-child {
      border-top: none;
    }

    &.selected {
      height: 24px;
      line-height: 24px;
      color: darken($thermaticBlueHSL, 10%);
      background: $white;

      &:hover {
        background: $white;
      }
    }

    &:hover {
      background: $gray20;
      cursor: pointer;
    }
  }

  &.disabled {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.6);
      cursor: not-allowed;
    }

    .combo-list {
      overflow-y: hidden;
    }

    li {
      width: 100%;
    }
  }
}

.yui-combotree {
  line-height: 36px;
  position: relative;

  .combotree {
    width: 100%;

    &.active {
      display: block;
    }
  }

  .nav-list {
    border-bottom: 1px solid $gray20;

    li {
      font-size: 13px;
      height: 24px;
      line-height: 24px;
    }

    .title-groups {
      padding-top: 2px;
      font-size: 12px;

      span {
        height: 20px;
        float: left;
        line-height: 20px;
      }

      span:last-child {
        padding: 0 $gutter;
        float: right;
        cursor: pointer;
      }
    }

    .rooftop-selections {
      color: $gray90;
      background: $white;
      border: 1px solid $gray20;
      border-width: 1px 1px 0 1px;

      i {
        margin: 0 16px;
      }

      &:first-child {
        border-top: none;
      }

      &:hover {
        background: $white;
        cursor: pointer;
      }
    }
  }

  .combo-list {
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid $gray20;
    border-width: 0 1px 1px 1px;

    li {
      margin: 0;
      padding: 0;
      color: $gray60;
      font-size: 11px;
      text-indent: math.div($gutter, 2);
      white-space: nowrap;
      background: $gray10;

      .list-row {
        border-bottom: 1px solid $gray40;
      }

      span {
        display: block;
        height: 36px;
        line-height: 36px;
      }

      .assign {
        display: inline;
        margin-right: $gutter;
        float: right;
        font-size: 14px;
      }

      i {
        padding: 0 math.div($gutter, 2);
      }

      &:first-child {
        border-top: none;
      }

      &.selected {
        background: #ccdadc;
      }

      &:hover {
        background: $gray05;
        cursor: pointer;
      }

      &.expand ul.sub {
        display: block;
      }
    }

    li.title-groups span {
      height: 22px;
      line-height: 22px;
      font-weight: 700;
    }

    ul.sub {
      display: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0;
        background: $gray20;
        border-top: 1px solid $gray30;

        &:first-child {
          border-bottom: none;
        }
      }

      span {
        text-indent: $gutter;
        height: 28px;
        line-height: 28px;
      }
    }

    li.disabled {
      color: $gray40;
      cursor: not-allowed;

      button {
        display: none;
      }
    }
  }
}

.nav-tree {
  width: 100%;
  color: $white;
  position: relative;

  .nav-heading {
    overflow: hidden;
    &:after {
      content: '\f0d7';
      font-family: 'FontAwesome';
      display: inline-block;
      position: absolute;
      right: 10px;
      bottom: 0px;
      pointer-events: none; // make the down-arrow inherit its parent's clickable state
    }
  }

  .backdrop {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 990;
    background: rgba(255, 255, 255, 0.2);
  }

  .combo-select {
    padding: 2px $gutter;
    height: 50px;
    font-family: $header-font-family;
    background: desaturate(darken($thermaticBlueHSL, 10%), 10%);

    .select-type {
      display: block;
      height: 16px;
      font-size: 10px;
      line-height: 16px;
    }

    .select-name {
      display: block;
      height: 30px;
      font-size: 16px;
      line-height: 30px;
    }

    &:hover {
      cursor: pointer;
      background: desaturate(darken($thermaticBlueHSL, 8%), 10%);
    }
  }
  .nav-box {
    z-index: 10000;
    background: #f2f2f2;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);

    .row,
    .col-sm-3,
    .col-sm-9 {
      padding: 0;
      margin: 0;
    }

    $dropdown-height: 400px;
    $dropdown-width: 600px !important;

    .dropdown-menu {
      min-width: $dropdown-width;
      min-height: $dropdown-height;
      background: #f2f2f2;
      padding: 0;

      &.active {
        display: block !important;
      }

      li a {
        padding: 5px 15px;
        font-weight: 300;
        font-size: 12px;
        color: #2896a6 !important;
      }

      .left-col {
        background: #e8e8e8;
        min-height: $dropdown-height;
        -webkit-box-shadow: 5px 0 5px -5px darken(#e8e8e8, 20%);
        -moz-box-shadow: 5px 0 5px -5px darken(#e8e8e8, 20%);
        box-shadow: 5px 0 5px -5px darken(#e8e8e8, 20%);
        border-right: 1px solid #efefef;
        .advertiser-groups {
          background: #e1e1e1;
          text-align: center;
          color: #d32712;
          font-size: 12px;
          font-weight: bold;
          border-bottom: 1px solid #d6d6d6;
        }

        ul li:first-child {
          margin-top: 10px;
        }

        .divider {
          background: #d6d6d6;
        }
      }

      @media (max-width: 767px) {
        .left-col {
          min-height: 0;
          margin-bottom: 15px;
        }
      }

      .right-col {
        max-width: 500px;
        .breadcrumb-container {
          background: #4b9ea7;
          color: #3d4243;
          font-size: 12px;
          padding: 0 10px;
          border-bottom: 1px solid #3dabb7;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-around;
          min-width: 0;

          .breadcrumb-item {
            color: white;
            display: inline-block;

            padding: 0 5px;
            min-width: 50px;

            & [data-clickable] {
              cursor: pointer;
            }

            & div:first-child,
            & div:last-child {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }

            .breadcrumb-collapse {
              text-align: center;
              span {
                padding: 5px;
                color: #3dabb7;
                background-color: #fff;
              }
            }
          }
        }

        .list-title {
          color: #d32712;
          font-weight: bold;
          font-size: 10px;
          line-height: 20px;
          padding-left: 10px;
          margin: 0;
        }

        .no-results {
          color: #d32712 !important;
        }
      }
    }
    .dropdown-menu &.columns-2 {
      min-width: 400px;
    }

    .multi-column-dropdown {
      list-style: none;
      margin: 0px;
      padding: 0px;
      overflow-y: scroll;
      max-height: 349px;
      .category-name {
        color: #333;
      }
      li a {
        display: block;
        clear: both;
        line-height: 1.428571429;
        color: #333;
        white-space: normal;

        &:hover,
        &.active {
          text-decoration: none;
          color: #fff !important;
          background-color: #3396a6;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .dropdown-menu.multi-column {
      min-width: 240px !important;
      overflow-x: hidden;
    }
  }
}

.nav-list {
  li {
    font-size: 13px;
    height: 30px;
    line-height: 30px;
  }

  .rooftop-selections {
    color: desaturate(darken($thermaticBlueHSL, 20%), 30%);
    background: #f1f1f1;
    border-top: 1px solid desaturate(darken($thermaticBlueHSL, 20%), 30%);

    i {
      margin: 0 16px;
    }

    &:first-child {
      border-top: none;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.combo-list {
  max-height: 340px;
  overflow-y: auto;

  li {
    margin: 0;
    padding: 0;
    color: $white;
    font-size: 11px;
    text-indent: math.div($gutter, 2);
    white-space: nowrap;
    background: #34757c;
    border-top: 1px solid $gray30;

    span {
      display: block;
      height: 40px;
      line-height: 40px;
    }

    i {
      padding: 0 math.div($gutter, 2);
    }

    &:first-child {
      border-top: none;
    }

    &.selected {
      ul.sub .selected {
        color: #9bc5cb;
      }
    }

    &:hover {
      background: #4b9ea7;
      cursor: pointer;
    }

    &.expand ul.sub {
      display: block;
    }
  }

  li.title-groups span {
    height: 22px;
    line-height: 22px;
    font-weight: 700;
  }

  li.empty {
    color: $gray70;
  }

  ul.sub {
    display: none;
    margin: 0;
    padding: 0;
    border-top: 1px solid $gray30;

    li {
      margin: 0;
      background: $gray50;
      border-bottom: none;
    }
    li:hover {
      background: $gray40;
    }

    span {
      text-indent: $gutter;
      height: 32px;
      line-height: 32px;
    }
  }

  button {
    padding: 0 2px;
    margin: 0;
    color: $white;
    line-height: inherit;
    border: none;
  }
}

.yui-list {
  @include yui-panel;

  ul,
  ol {
    max-height: 400px;
    overflow-y: auto;
  }

  .title-row {
    padding: $gutter;
    font-weight: 600;
    border-bottom: 1px solid $gray20;
  }

  .list-row {
    padding: math.div($gutter, 2) $gutter;
    line-height: 24px;
    border-top: 1px dashed #e6e6e6;

    &:first-child {
      border-top: none;
    }
  }
}

.yui-fileupload,
.yui-uploader {
  input[type='file'] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  label {
    margin: 0;
    padding: $goldenEm 0 0 0;
    width: 100%;
    display: block;
    color: $gray50;
    background: $gray03;
    border: 1px dashed $gray50;
    cursor: pointer;
    @include border-radius(4px);

    p.formats {
      color: $gray70;

      i {
        margin: 0 5px;
      }
    }

    p.text {
      text-align: center;
      display: inline-block;
    }
    p.icon {
      font-size: 24px;
    }

    &.error {
      background: rgba(223, 185, 185, 0.5);
    }

    &.success {
      background: rgba(185, 223, 186, 0.5);
    }
  }

  label.ready {
    background: #e9eaeb;

    .text {
      color: darken(desaturate($thermaticBlueHSL, 30%), 20%);
    }

    .icon {
      color: darken(desaturate($thermaticBlueHSL, 30%), 20%);
    }
  }
}

.yui-nogo {
  .label-text {
    display: block;
  }

  li.list-row {
    border-top: 1px solid $gray10;
  }
  li.list-row:first-child {
    border-top: none;
  }

  li.list-row:nth-child(odd) {
    background: $gray05;
  }
  li.list-row:nth-child(even) {
    background: $white;
  }
}

@media (max-width: $breakpoint-small-screen) {
  .pri-pulldown {
    margin-bottom: $gutter;
  }

  .pri-dropdown {
    .dropdown-select .select-name {
      font-weight: normal;
    }
  }

  .pri-lightbox .thumb {
    width: 160px;
    height: 130px;
  }

  .nav-tree {
    .combo-list ul.sub span {
      height: 36px;
      line-height: 36px;
    }
  }
}
