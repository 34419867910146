/*
  BORDER BOX RESET
*/
html {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
    width: 100%;
    min-height: 100%;
    position: relative;
}

/* Contain floats: nicolasgallagher.com/micro-clearfix-hack/ */
.clearfix {
    zoom: 1;
    &:before, &:after {
        content: ''; display: table;
    }
    &:after { clear: both; }
}

/*
  DEFAULT STYLES
*/

a { text-decoration: none; }

ul, ol { margin: 0; padding: 0; list-style: none; }


.paragraph-margins { margin: 0 0 .75 * $gutter; }
.grid-margins      { margin: 0 0 $gutter;       }
.section-margins   { margin: 0 0 2 * $gutter;   }


/*
  BASIC STYLES & ALIGNMENTS
*/
.hidden { display: none; }
.left { float: left; padding-right: $gutter; }
.right { float: right; padding-left: $gutter; }
.centered { text-align: center; }
@mixin list-flex {
  .list-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
  }
}

.left-aligned {
  text-align: left;

  @media (max-width: $breakpoint-small-screen) {
    text-align: center;
  }
}

.right-aligned {
  text-align: right;

  @media (max-width: $breakpoint-small-screen) {
    text-align: center;
  }
}
.stay-right{
  text-align: right;
}
.stay-left{
  text-align: left;
}
.left-to-right {
  text-align: left;

  @media (max-width: $breakpoint-small-screen) {
    text-align: right;
  }
}
.right-to-left {
  text-align: right;

  @media (max-width: $breakpoint-small-screen) {
    text-align: left;
  }
}

.clear { clear: both; }
.clear-after:after { content: ''; display: table; clear: both; }

/*
  HEADER SETTINGS (LARGEST TO SMALLEST)
*/
.tera {
  font-size: 5.558 * $base-font-size;
  line-height: 1;
}
.giga {
  font-size: 4.632 * $base-font-size;
  line-height: 1;
}
.mega {
  font-size: 3.334 * $base-font-size;
  line-height: 1;
}

h1, h2, h3, h4, h5, h6 { margin: 0; }

/*
  SMALLER
*/
.alpha, h1 {
  font-size: 2.779 * $base-font-size;
  line-height: 1.1;
}
.beta, h2 {
  font-size: 2 * $base-font-size;
  line-height: 1.1;
}
.gamma, h3 {
  font-size: 1.667 * $base-font-size;
  line-height: 1.2;
}
.delta, h4 {
  font-size: 1.2 * $base-font-size;
  line-height: 1.4;
}
.epsilon, h5 {
  font-size: 1 * $base-font-size;
  line-height: 1.6;
}
@mixin zeta {
  .zeta, h6 {
    font-size: 0.72 * $base-font-size;
    line-height: 1.6;
  }
}
/*
  BASIC GRID
*/
.row {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: $gutter;
  @include box-clearfix();

  &>* { margin-bottom: $gutter; }
}

.column {
  float: left;
  padding-left: $gutter;
  padding-right: $gutter;
  padding-bottom: $gutter;
  @include box-sizing();
}

@mixin rowflush () {
    .row{
        &.flush {
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 0;
          @include box-clearfix();

          &>* { margin-bottom: 0; }

          & .column {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
          }
        }
    }
}

.column.full { width: 100%; }
.column.three-quarters { width: 75%; }
.column.two-thirds { width: 66.6%; }
.column.half { width: 50%; }
.column.third { width: 33.3%; }
.column.fourth { width: 25%; }
.column.fifth, .column.two-tenths { width: 20%; }
.column.sixth { width: 16.6%; }
.column.tenth { width: 10%; }
.column.flow-opposite { float: right; }

@media (max-width: $breakpoint-small-screen) {
  .row {
  }
  .row.flush {
    padding-bottom: $gutter;
  }
}

/*
  MEDIA QUERIES
*/
/* Show and hide depending on screen size. */
@media (max-width: $breakpoint-small-screen) {
  .show-on-large, .show-on-medium, .hide-on-small { display: none; }
}
@media (min-width: $breakpoint-small-screen) {
  .hide-on-large, .show-on-medium, .show-on-small { display: none; }
}

@media (max-width: $breakpoint-mid-screen) {
  .hide-on-medium {display: none; }
}
