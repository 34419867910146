
/******************************************
* THEME STYLESHEET
******************************************/
@import '~styles/theme/theme.scss';
@import '~styles/base/_variables.scss';
@import '~styles/base/_mixins.scss';

@mixin btnstyle {
  border:none; outline: none; border-radius: 0px !important;
}
@mixin btnshadow {
  -webkit-border-radius: 1.50px;
  -moz-border-radius: 1.50px;
  border-radius: 1.50px;
  -webkit-box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
}

button, input[type='button'], input[type='submit'] {
  color: $black;
  background-color: transparent;
  cursor: pointer;
  @include border-radius(4px);
}

.xxl { padding: 1.5em;  width: 100%; }
.xl { padding: 1em;  width: 75%; }
.lg { padding: .5em; width: 50%; }
.sm { width: 25%; }
.xsm { width: 12%; }


.btn-primary, .btn-primary-thermatic {
    color: white;
    background-color: #067e8b;
    border-color: #03707c;

    &:hover, &:active, &:link, &:focus {
      color: white;
      background-color: #176574;
    }
}

.btn-default-thermatic {
    background: transparent;
    border: 1px solid $gray20;
    color: black;

    &:hover, &:active, &:link, &:focus {
      background: rgba(0, 0, 0, 0.05);
    }
}

.btn-success {
  background-color: #57ad80;
  border-color: #53a178;

    &:hover {
      background-color: #458961;
    }
}

.progress-bar { background-color: #fb8c00; }
.withbtn { top: 5px; position: relative; }
::ng-deep .ui-widget button.newbtn { font-size: 1em !important; }
.btn { font-size: 13px; border-radius: 1.50px; outline: none; }
button, input[type='button'], input[type='submit'] { border-radius: 1.50px; -webkit-border-radius: 1.5px; -moz-border-radius: 1.5px; border-radius: 1.5px; }
.ui-button { width: auto; text-transform: uppercase; padding: 0 16px; font-weight: 600; font-size: 14px; font-family: "RobotoMedium", "Trebuchet MS", Arial, Helvetica, sans-serif; }
button { position: relative;}
:host ::ng-deep .newbtn, :host ::ng-deep .editbtn, :host ::ng-deep .deletebtn,
:host ::ng-deep .closebtn, ::ng-deep .savebtn, :host ::ng-deep .viewbtn,
:host ::ng-deep .downloadbtn, .ui-widget button, ::ng-deep .q-button {
  color: white; font-size: 14px !important; height: 36px; padding: 8px 16px;
  font-weight: 500; display: inline-block;
  text-decoration: none; text-align:center; text-transform: uppercase;
  font-family: "RobotoMedium", "Trebuchet MS", Arial, Helvetica, sans-serif !important;
  @include btnshadow;
  outline: none;
  position:relative;overflow:hidden;transform:translate3d(0,0,0);

  .fa {
    color: white !important;
  }
  &:hover, &:focus, &:active{
    color: white;
  }

  &:after {content:"";display:block;position:absolute;width:100%;height:100%;top:0;left:0;pointer-events:none;background-image:radial-gradient(circle,#fff 10%,transparent 10.01%);background-repeat:no-repeat;background-position:50%;transform:scale(10,10);opacity:0;transition:transform .5s,opacity 1s;}

  &:active:after{transform:scale(0,0);opacity:.5;transition:0s}
}

.newbtn, .ui-fileupload-choose input {
  background-color: $button_maincolor;  border: 1px solid $button_maincolor;

  &:hover, &:focus, &:active, &:hover[disabled], &:active:hover{
    background-color: $button_darkcolor; border: 1px solid $button_darkcolor;
  }

}

::ng-deep .q-button {
  background-color: $button_maincolor !important;  border: 1px solid $button_maincolor !important;
  &:hover, &:focus, &:active, &:hover[disabled], &:active:hover{
    background-color: $button_darkcolor !important; border: 1px solid $button_darkcolor !important;
  }
}
::ng-deep .q-button i::before { color: white; }
body .ui-fileupload-choose:not(.ui-state-disabled):hover.ui-button {
  background-color: $primary_darkcolor; border: 1px solid $primary_darkcolor;
}

:host ::ng-deep .editbtn{
  background-color: $button_lightcolor; border: 1px solid $button_lightcolor;

  &:hover, &:focus, &:active, &:hover[disabled], &:active:hover{
    background-color: $button_darkcolor !important; border: 1px solid $button_darkcolor !important;
  }
}

.downloadbtn{
  background-color: $button_darkcolor; border: 1px solid $button_darkcolor;
  &:hover, &:focus, &:active, &:hover[disabled], &:active:hover{
    background-color: $button_maincolor !important; border: 1px solid $button_maincolor !important;
  }
}

.deletebtn{
  background-color: $dismissive_main; border: 1px solid $dismissive_main;

  &:hover, &:focus, &:active, &:hover[disabled], &:active:hover {
    background-color: $dismissive_hover; border: 1px solid $dismissive_hover;
  }
}

.deleteiconbtn, .editiconbtn, .swapiconbtn, .canceliconbtn, .copyiconbtn, .saveiconbtn, .addiconbtn, .downloadiconbtn, .settingiconbtn, .searchiconbtn, .campaignpreviewiconbtn {background: transparent; border: none; box-shadow: none !important; padding: 5px 0px!important; height:  33px !important; border-radius: 0px !important; width: 33px !important;
  &.small { height:  27px !important; width: 24px !important; }
}

.deleteiconbtn {

  &::before { content: url("../../assets/img/ic_delete_main.svg"); }
  &.small {
    &::before { content: url("../../assets/img/ic_delete_main_small.svg") !important; }
  }

  &:hover, &:focus, &:active, &:hover[disabled], &:active:hover {
    background: #f344231c; @include btnstyle;
  }

}
.copyiconbtn {

  &::before { content: url("../../assets/img/ic-file_copy.svg"); top: 2px; position: relative; }
  &:hover, &:focus, &:active, &:hover[disabled], &:active:hover {
    background: rgba(2, 92, 178, 0.12156862745098039); @include btnstyle;
  }

}

.editiconbtn {
  &::before { content: url("../../assets/img/ic_edit_main.svg") }
  &.small {
    &::before { content: url("../../assets/img/ic_edit_main_small.svg") !important; }
  }
  &:hover, &:focus, &:active, &:hover[disabled], &:active:hover {
    background: rgba(2, 92, 178, 0.12156862745098039); @include btnstyle;
  }
}
.canceliconbtn {
  &::before { content: url("../../assets/img/ic-cancel-main.svg") }
  &.small {
    &::before { content: url("../../assets/img/ic-cancel-main_small.svg") !important; }
  }
  &:hover, &:focus, &:active, &:hover[disabled], &:active:hover {
    background: rgba(2, 92, 178, 0.12156862745098039); @include btnstyle;
  }
}
.swapiconbtn {
  &::before { content: url("../../assets/img/ic_swap_main.svg") }
  &:hover, &:focus, &:active, &:hover[disabled], &:active:hover {
    background: rgba(2, 92, 178, 0.12156862745098039); @include btnstyle;
  }

}
.searchiconbtn {

  &::before { content: url("../../assets/img/search.svg"); }

  &:hover, &:focus, &:active, &:hover[disabled], &:active:hover {
    background: rgba(2, 92, 178, 0.12156862745098039); @include btnstyle;
  }
}
.saveiconbtn {
  &::before { content: url("../../assets/img/ic-save-main.svg") }
  &:hover, &:focus, &:active, &:hover[disabled], &:active:hover {
    background: rgba(2, 92, 178, 0.12156862745098039); @include btnstyle;
  }
}
.confirmiconbtn {
  &::before { content: url("../../assets/img/check_circle.svg"); border:none; outline: none; border-radius: 0px !important }
  &:hover, &:focus, &:active, &:hover[disabled], &:active:hover {
    background: rgba(2, 92, 178, 0.12156862745098039); border:none; outline: none; border-radius: 0px !important;
  }
}

.confirmiconbtngreen {
  border:none; outline: none; border-radius: 0px !important;
  &::before { content: url("../../assets/img/check_circle_green.svg") }
  &:hover, &:focus, &:active, &:hover[disabled], &:active:hover {
    background: rgba(2, 110, 25, 0.075); border:none; outline: none; border-radius: 0px !important;
  }
}

.addiconbtn {

  &::before { content: url("../../assets/img/ic_add_box_main.svg") }

  &:hover, &:focus, &:active, &:hover[disabled], &:active:hover {
    background: rgba(2, 92, 178, 0.12156862745098039); @include btnstyle;
  }

}
.downloadiconbtn {

  &::before { content: url("../../assets/img/ic_file_download_main.svg") }

  &:hover, &:focus, &:active, &:hover[disabled], &:active:hover {
    background: rgba(2, 92, 178, 0.12156862745098039); @include btnstyle;
  }

}

.settingiconbtn {

  &::before { content: url("../../assets/img/ic_settings_main.svg") }

  &:hover, &:focus, &:active, &:hover[disabled], &:active:hover {
    background: rgba(2, 92, 178, 0.12156862745098039); @include btnstyle;
  }

}

.campaignpreviewiconbtn {

  &::before { content: url("../../assets/img/baseline-remove_red_eye-24px.svg") }

  &:hover, &:focus, &:active, &:hover[disabled], &:active:hover {
    background: rgba(2, 92, 178, 0.12156862745098039); @include btnstyle;
  }

}

.flex-container .deleteiconbtn { margin-top: 4px; }
.closebtn{
  background-color: #00bcd4; border: 1px solid #00adc0;

  &:hover, &:focus, &:active, &:hover[disabled], &:active:hover{
    background-color: #00adc0; border: 1px solid #009caa;
  }
}

.savebtn{
  background-color: $button_maincolor; border: 1px solid $button_maincolor;

  &:hover, &:focus, &:active, &:hover[disabled], &:active:hover{
    background-color: $button_darkcolor; border: 1px solid $button_darkcolor;
  }
}

.viewbtn{
  background-color: $button_lightcolor; border: 1px solid $button_lightcolor;

  &:hover, &:focus, &:active, &:hover[disabled], &:active:hover{
    background-color: $button_maincolor; border: 1px solid $button_maincolor;
  }
}

.cancelbtn, button.cancelbtn {
  color: #333 !important; background: transparent; border: none; box-shadow: none; color: $button_maincolor !important; text-transform:uppercase;
  font-family:"RobotoMedium", "Helvetica Neue", sans-serif !important;

  .fa { color: #333 !important; }

  &:hover, &:focus, &:active, &:hover[disabled], &:active:hover{
    background-color: #e5e5e5; border: #e6e6e6 1px solid;
    background-color: rgba(0, 92, 178, 0.16862745098039217); border: none; color: $button_maincolor !important;
  }
}

.deleteiconbtn, .editiconbtn, .swapiconbtn, .canceliconbtn, .saveiconbtn, .confirmiconbtn, .addiconbtn, .downloadiconbtn, .settingiconbtn, .copyiconbtn, .campaignpreviewiconbtn {
  background: transparent; border: none; box-shadow: none !important; padding: 5px 0px!important; height:  33px !important; border-radius: 0px !important; width: 33px !important;
}

.runbtn {font-family: "RobotoMedium", "Trebuchet MS", Arial, Helvetica, sans-serif !important; width: auto; margin-left: 20px; height: 37px; position: relative; top: 1px; padding: .4em .9em; }

.ripple{position:relative;overflow:hidden;transform:translate3d(0,0,0);}
.ripple:after{content:"";display:block;position:absolute;width:100%;height:100%;top:0;left:0;pointer-events:none;background-image:radial-gradient(circle,#000 10%,transparent 10.01%);background-repeat:no-repeat;background-position:50%;transform:scale(10,10);opacity:0;transition:transform .5s,opacity 1s}
.ripple:active:after{transform:scale(0,0);opacity:.2;transition:0s;}
.saveiconbtn.calen_r{ position: relative;top: 3px;padding: 16px 10px 7px 10px !important;border-radius: 50% !important;
  &::before{content: url("../../assets/img/baseline-calendar_today.svg"); }
  &:hover, &:focus { border-radius: 50% !important; transition: 0.3s; background: rgba(30, 136, 229, 0.12); }
}

::ng-deep .mat-dialog-actions .mat-button+.mat-button, .mat-dialog-actions .mat-button+.mat-raised-button, .mat-dialog-actions .mat-raised-button+.mat-button, .mat-dialog-actions .mat-raised-button+.mat-raised-button
{line-height: 0px;}

::ng-deep .ui-widget button {
  &.mat-warn, &.mat-primary, &.mat-accent { height: auto; box-shadow: none; border-radius: 50% !important; padding: 0px;}
  &.mat-warn {
    &:hover, &:focus { color: $dismissive_main;}
  }
  &.mat-primary {
    &:hover, &:focus { color: $primary_maincolor;}
  }
  &.mat-accent {
    &:hover, &:focus { color: $secondary_maincolor;}
  }
}
.flat { box-shadow: none; }
::ng-deep .time_picker_{
  .btn-link {background: transparent; color: grey;
    &:hover { background: rgb(241, 241, 241); color: grey }
  }
  .btn-outline-primary, .btn-outline-primary.active, .btn-outline-primary:active { box-shadow: none; background: transparent; color: grey;
    &:hover, &:focus { background: rgb(241, 241, 241); color: grey }
  }
}
::ng-deep .btn-link { height: 25px !important; padding: 0px 14px !important; box-shadow: none !important; background-color:$primary_maincolor; line-height: 0px;
  &:focus, &:hover { background-color:$primary_darkcolor; }
}

::ng-deep .btn-outline-primary, .btn-outline-primary.active, .btn-outline-primary:active, .btn-outline-primary[disabled]
{background-color:$secondary_maincolor;}
::ng-deep .btn-outline-primary:focus, ::ng-deep .btn-outline-primary:hover { background: $secondary_lightcolor ;}

/*# sourceMappingURL=template.component.css.map */
::ng-deep .campaign-ad-dates{
  .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-icon-button {
    border-radius: 50% !important;
    height: 30px;
    width: 30px; font-size: 18px !important;
    &:focus, &:active, &:hover { border-radius: 50% !important; height: 30px; width: 30px; }
  }
}

.adsettings {
  .switchlabel {
    span {
      &:first-child, &:last-child {display: none !important; }
    }
  }
}
