
@import "styles/base/_normalize"; /* CSS Reset/Normalize */
@import "styles/base/_variables";
@import "styles/base/_mixins";

@font-face {font-family: "RobotoRegular"; src: url("./assets/fonts/tranabmd_Roboto_Regular.ttf");}
@font-face {font-family: "RobotoMedium"; src: url("./assets/fonts/tranabmd_Roboto_Medium.ttf");}
@font-face {font-family: "StradaOTSemibd"; src: url("./assets/fonts/tranabmd_StradaOT_Semibd.otf");}

body {
  background-color: $gray05;
  font-size: 14px;
  line-height: 1.6;
  font-family: $base-font-family;
  color: black;
  padding-bottom: $footer-height;
  position: relative;

  &.shadow {
    #header {
      @include md-line-shadow();
    }
  }
}

ul {
  padding: none;
  margin: none;
}
li {
  list-style: none;
}

h3,h4,h5,h6,li, p, select {
		font-family: "RobotoRegular";
}

/* header fonts */
h1 {
	font-size: 30px;
	font-family: "RobotoMedium";
}
h2 {
	font-family: "RobotoMedium";
	font-size: 26px;

}
/* label fonts */
h3 {
	font-size: 20px;

}
/* body content */
h4 {
	font-size: 16px;

}

li {
	font-size: 16px;
}

p {
	font-size: 16px;

}

/* table--------------------------------------------- */
table {
}

th {
	font-family: "RobotoMedium";
	font-weight: 500;
	font-size: 20px;

}
/* table data fonts */
td {
	font-family: "RobotoRegular";
	font-size: 18px;
	vertical-align: middle !important;
}

.inlineBlock {
	display: inline-block;
}
.clearFix {
	clear: both;
}
.spacer50 {
	height: 50px;
}
.spacer100 {
	height: 50px;
}
// LOADING DATA-------------------------------------------------------------
.page-mask {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
	z-index: 1500;
  background-color: rgba(0,0,0,0.6);
}

.window {
	z-index: 1500;
  position: fixed;
  left: 30%;
  right: 30%;
  bottom: 30%;
  top: 30%;
	height: 300px;
  background-color: white;
  border: solid 2px gray;
}
.spinnerContainer {
	margin: 50px auto;
	width: 80%;
}
