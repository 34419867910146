/*
  MIXINS
*/

@mixin md-box-shadow() {
   -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
  -moz-box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
}


@mixin md-line-shadow (){
  -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
  -moz-box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
}

@mixin md-side-shadow (){
  -webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12),0 2px 4px -1px rgba(0,0,0,.2);
  -moz-box-shadow: 0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12),0 2px 4px -1px rgba(0,0,0,.2);
  box-shadow: 0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12),0 2px 4px -1px rgba(0,0,0,.2);
}

@mixin md-soft-shadow (){
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
  -moz-box-shadow: 0 6px 12px rgba(0,0,0,.175);
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
}

@mixin md-inset-shadow (){
  -webkit-box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.075), inset -1px -1px 5px 0 rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.075), inset -1px -1px 5px 0 rgba(0, 0, 0, 0.075);
  box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.075), inset -1px -1px 5px 0 rgba(0, 0, 0, 0.075);
}

@mixin border-radius ($radius: 5px){
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin box-sizing(){
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

@mixin box-clearfix(){
  zoom: 1;
  &:before, &:after { content: " "; display: table; }
  &:after { clear: both; }
}

@mixin box-shadow ($x: 2px, $y: 2px, $offset: 2px, $color: #000000){
  box-shadow: $x $y $offset $color;
  -webkit-box-shadow: $x $y $offset $color;
  -moz-box-shadow: $x $y $offset $color;
}

@mixin box-shadow-inset ($x: 2px, $y: 2px, $offset: 2px, $color: #000000){
  box-shadow: $x $y $offset $color inset;
  -webkit-box-shadow: $x $y $offset $color inset;
  -moz-box-shadow: $x $y $offset $color inset;
}
@mixin linear-gradient($c1: $gray20, $c2: $gray40){
  background: -moz-linear-gradient(top, $c1 0%, $c2 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$c1), color-stop(100%,$c2));
  background: -webkit-linear-gradient(top, $c1 0%, $c2 100%);
  background: linear-gradient(top, $c1 0%, $c2 100%);
}

@mixin opacity($opacity: 0.5){
  $ieopacity: ($opacity * 100);
  zoom: 1;
  opacity: $opacity;
  filter: alpha(opacity=$ieopacity);
}
@mixin text-shadow ($x: 3px, $y: 3px, $offset: 4px, $color: $baseShadow){
  text-shadow: $x $y $offset $color;
  -moz-text-shadow: $x $y $offset $color;
  -webkit-text-shadow: $x $y $offset $color;
}
@mixin text-inset ($hColor: $hilite, $sColor: $shadow){
  text-shadow: 1px 1px 0 $hColor, -1px -1px 0 $sColor;
  -moz-text-shadow: 1px 1px 0 $hColor, -1px -1px 0 $sColor;
  -webkit-text-shadow: 1px 1px 0 $hColor, -1px -1px 0 $sColor;
}

/********
  scroll bars
*********/
@mixin scrollbars($size, $foreground-color, $background-color: white) {
  // For Google Chrome
  ::-webkit-scrollbar {
      width:  $size;
      height: $size;
  }

  ::-webkit-scrollbar-thumb {
      background: $foreground-color;
  }

  ::-webkit-scrollbar-track {
      background: $background-color;
  }
}


/****
  Animation
*****/
@mixin animate($name, $dur: 1s, $delay: 0s, $count: 1, $timing: ease){
    animation-name: $name;

    -webkit-animation-duration: $dur;
    -moz-animation-duration: $dur;
    -o-animation-duration: $dur;
    animation-duration: $dur;

    -webkit-animation-delay: $delay;
    -moz-animation-delay: $delay;
    -o-animation-delay: $delay;
    animation-delay: $delay;

    -webkit-animation-iteration-count: $count;
    -moz-animation-iteration-count: $count;
    -o-animation-iteration-count: $count;
    animation-iteration-count: $count;

    -webkit-animation-timing-function: $timing;
    -moz-animation-timing-function: $timing;
    -o-animation-timing-function: $timing;
    animation-timing-function: $timing;
}


@keyframes slideRight {
  from {
    margin-left: 100%;
  }

  to {
    margin-left: 0;
  }
}

@keyframes slideLeft {
  from {
    margin-left: -100%;
  }

  to {
    margin-left: 0%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@mixin inputstyle {
  &:focus, &:hover { outline: none; }
  &:hover { border-color: #fb8c00 !important; }
  &:focus { border-bottom-width: 2px; border-color: #fb8c00 !important; }
}

@mixin growlicon {
  background: white;
  border-radius: 20px;
  height: 36px;
  padding: 2px;
  position: absolute; left: 12px;
  display: block;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
