.ui-dropdown .ui-dropdown-trigger {height: 1em !important; border-left: 0px; }
.ui-widget-content a {
    color: $primary_maincolor;
    &:hover { text-decoration: underline; }
    &.ad-name {
      @include tblliststyle;
    }
  
    &:hover.ad-name { color: $primary_darkcolor; text-decoration: underline; }
    &.txtbtn { color: white; text-decoration: none; }
  }
  body .ui-panel { margin-bottom: 30px; }

.ui-widget-header .ui-button, .ui-widget-content .ui-button, .ui-widget.ui-button, .ui-button { background: $button_lightcolor !important; border: none !important; }
.ui-datatable-tablewrapper {
    td { padding: 15px 5px !important; border-right: none; border-left: 0; border-bottom: 1px solid #E6E6E6; }
    tr:nth-child(odd)		{ background-color:#fff; }
    tr:nth-child(even)		{ background-color:#fff; }
    thead th { padding: 15px 5px !important; border-left: none;  border-bottom: 2px solid #e6e6e6; background: white; }
    .ui-state-default { border: none !important; }
  }

.ui-g-12 .ui-panel.ui-widget .ui-panel-titlebar { border: 1px solid #d8d8d8; margin-top:-1px; border-width: 1px 0px 1px 0px; }

body .ui-widget-header { color: #676767; border: 1px solid #d8d8d8;  font-weight: bold; }
.ui-panel-titlebar button { margin-top: 3px; }
body .ui-panel .ui-panel-content {padding: 2em 2em;}
body .ui-inputtext { padding-left: 6px; }
body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon { font-size: 1.05em; margin-left: -1px; margin-top: -1px; }
body .ui-widget-content .fa .label-orange { background-color: #fd6f00; padding: 3px 5px; border-radius: 50%; font-size: 12px; color: white; }
body {
  .ui-datatable{
    .ui-datatable-data{
      tr{
        &.ui-datatable-even { background: white; }
      }
    }
  }
  .ui-tabview {
    &.ui-tabview-top {
      > .ui-tabview-nav { border-radius: 0px; border: 1px solid #dbdbdb;
        li { border-width: 0 0 3px 0; color: transparent; }
      }
    }
  }
  .ui-overlaypanel {
    .ui-overlaypanel-close { background: $primary_lightcolor; height: 2.1em; border: 1px solid #6ab7fe;
      &:hover { background: $primary_maincolor; }
    }
  }
  .ui-panel {
    .ui-panel-content { padding: 1em 1em; }
  }
  .ui-datepicker {
    .ui-timepicker {
      > div a {
        .fa {
          &.fa-angle-up { color: #2d2d2d; }
          &.fa-angle-down { color: #3b3b3b; }
        }
      }
    }
  }

  .ui-button.ui-button-icon-only .fa.fa-angle-left {transform: rotate(0deg);}
  .ui-button:enabled:not(:focus):hover, body .ui-datepicker-buttonpane > button:enabled:not(:focus):hover { background: $primary_maincolor; }
  .ui-dropdown-panel {
    .ui-dropdown-filter-container { border-bottom: 0px; background-color: #ffffff;
      .fa { top: 10px; color: #2f2f2f; }
      input { color: #4d4d4d;
        &:focus { border-bottom-color: #c6c6c6; }
      }
    }
    &.ui-shadow { box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12); }
    .ui-dropdown-item{
      &.ui-state-highlight { background: rgba(0,0,0,.12) !important; color: $primary_maincolor; }
    }
  }
  .ui-dropdown {
    .ui-dropdown-trigger { margin-top: 4px; background: white; }
    .ui-dropdown-label{
      &.ui-inputtext { padding-top: 3px; padding-bottom: 3px; text-align: left;}
    }
    &.ui-state-focus{
      .ui-dropdown-label {background: white !important; }
    }
  }
  .ui-dialog {
    .ui-dialog-titlebar {
      .ui-dialog-titlebar-icon {
        &:hover, &:focus { background: transparent; }
      }
    }
  }
  .ui-progressbar { height: 5px;
    .ui-progressbar-value { height: 5px; }
  }
  .ui-paginator {
    > a:not(.ui-state-disabled):not(.ui-state-active):hover {
      background: $secondary_darkcolor; border-color: $secondary_darkcolor; color: white;  text-decoration: none !important;
    }
    .ui-paginator-pages {
      a { color: $primary_maincolor; font-weight: 500; min-width: 2.2em; min-height: 2em; box-shadow: 0px 3px 1px #dddddd; line-height: 2em;
        &:hover { text-decoration: none; }
        &.ui-state-active { background-color: $primary_maincolor; }
        &:not(.ui-state-active):hover { background-color: $primary_darkcolor; color: white; }
      }
    }
    .ui-paginator-next, .ui-paginator-last, .ui-paginator-prev, .ui-paginator-first {border-radius: 12px; color: #adb9bb; text-decoration: none !important;
      background: white; border-color: #adb9bb; font-size: 20px !important; line-height: 22px !important; width: 25px !important; height: 25px !important; border-radius: 26px !important;}
  }
}

.ui-dialog { z-index: 2000002 !important;
  p { word-wrap: break-word; font-size: 13px; }
  .ui-dialog-titlebar-icon { font-size: 1.7em; background: none; margin-top: 3px !important; line-height: 0px; height: auto !important;
    &span { color: #3f3f40; }
    &:hover, &:focus { text-decoration: none; }
   }
}
.ui-sidebar .ui-sidebar-close { line-height: 5px; font-size: 25px; }
.ui-widget-overlay { z-index: 2000001 !important; }

.ui-progressbar.ui-progressbar-indeterminate .ui-progressbar-value {background-color: $progressbg; }
.ui-progressbar .ui-widget-header { background: linear-gradient(to bottom, $progressbar 0%, $progressbaralt 100%); }
.ui-dialog.ui-widget .ui-dialog-titlebar {
  padding: 0.5em 1.5em; background: white;  border-bottom: 1px solid #edeff1;
  .ui-dialog-titlebar-close { display: none; }
}
body .ui-state-highlight { background: $primary_darkcolor; }
body .ui-breadcrumb { background: #3e97a5; font-weight: normal;}
body .ui-datatable .ui-datatable-header, body .ui-datatable .ui-datatable-footer {
    background: #f6f7f9 0 0 repeat-x;
    background: linear-gradient(to bottom, #e2e2e2 0%, #ebedf0 100%);
    border: 1px #ccc solid !important;
    color: #444 !important;
    font-weight: bold;
    font-size: 17px;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    border-bottom: 0px !important;
}
.ui-datatable .ui-paginator-bottom {
    border: 1px solid #d9d9d9;
    border-top: 0px;
}
.ui-dropdown.ui-state-default { border-top: none; border-left: none; border-right: none; background: transparent;}
body .ui-tabview .ui-tabview-nav > li.ui-state-active a { color: $primary_darkcolor; font-weight: 600; }
.ui-state-default.ui-unselectable-text.ui-sortable-column { color: black; text-align: left;
  &:hover { background: transparent; }
}
.ui-table .ui-sortable-column:not(.ui-state-highlight):hover { background-color: #fdfdfd; }
.ui-table .ui-sortable-column.ui-state-highlight, .ui-table .ui-sortable-column.ui-state-highlight .ui-sortable-column-icon,
.ui-treetable .ui-sortable-column.ui-state-highlight .ui-sortable-column-icon { color: #949494;}
.ui-treetable .ui-sortable-column.ui-state-highlight { color: black; }
.ui-treetable .ui-sortable-column:not(.ui-state-highlight):hover { background-color: transparent; }
.ui-state-default.ui-unselectable-text.ui-sortable-column span { font-size: 16px; }
.ui-datatable-tablewrapper .ui-widget-content, body .ui-datatable .ui-datatable-data tr  { border: none; }
.ui-datatable .ui-datatable-data>tr>td { border: none; border-bottom: 1px solid #e6e5e5; }
.ui-datatable tbody > tr.ui-widget-content.ui-datatable-odd { background: white; }
body .ui-listbox:not(.ui-state-disabled) .ui-listbox-item:not(.ui-state-highlight), .ui-listbox.ui-state-disabled .ui-listbox-item { font-size: 12px; padding-top: 2px;padding-bottom: 2px;}
thead.ui-datatable-thead { border-bottom: 2px solid #e6e5e5; }
body .ui-panel .ui-panel-titlebar  .badge-success{
  top: -4px; position: relative; left: 5px; font-size: 13px; padding: 0.2em 0.6em 0.3em 0.6em;
}