$gutter: 16px;
$primary_maincolor:#1e88e5;
$primary_lightcolor:#6ab7ff;
$primary_darkcolor:#005cb2;
$primary_maincolor_50percent: #1e88e580;
$button_maincolor:#1e88e5;
$button_lightcolor:#6ab7ff;
$button_darkcolor:#005cb2;

$secondary_maincolor:#fb8c00;
$secondary_lightcolor:#F7941D;
$secondary_darkcolor:#c25e00;

$comparison_maincolor: #7d3f8c;

$dismissive_main:#F44336;
$dismissive_hover:#B71C1C;

$top-nav : #424242;
$left-nav : #616161;
$left-navTextColor:#ffffff;
$header_backgroundcolor: #4AA5FF;

$sidebar_background : #616161;
$sidebarlogoarea : rgba(36, 50, 59, 0.5);

$logarealogo : #424242;

/* Sidebar */
$sidebarLogoBgColor:rgba(36,50,59,0.5);
$sidebarBgColor:#616161;
$darkSidebarBgColor:#202d35;
/*$menuBgImageLight:'bg-moody-light.png'*/;
/*$menuBgImageDark:'bg-moody-dark.png'*/;

/* Primary */
$primaryColor:#607D8B;
$primaryDarkColor:#496475;
$primaryLightColor:#90A4AE;
$primaryLightestColor:#E8EAF6;
$primaryTextColor:#ffffff;

/* Accent */
$accentColor:#1e88e5;
$accentLightColor:#6AB7FF;
$accentTextColor:#ffffff;

/* Progress Bar */
$progressbg : #fb8c00;
$progressbar : #f6c182;
$progressbaralt : #febb69;

/* Topbar */
$topbarTextColor:#E8EAF6;
$topbarIconColor:#E8EAF6;

/* Submenu */
$submenuBgColor:rgba(255,255,255,0.2);
$darkSubmenuBgColor:rgba(255,255,255,0.15);

/* Default MenuItem */
$menuitemTextColor:#6AB7FF;
$menuitemIconTextColor:#ffffff;

/* Hover MenuItem */
$menuitemHoverBgColor:rgba(0,0,0,0.4);
$menuitemHoverTextColor:#ffffff;
$menuitemHoverIconTextColor:#ffffff;

/* Active MenuItem */
$menuitemActiveBgColor:#7CB342;
$menuitemActiveTextColor:#ffffff;
$menuitemActiveIconTextColor:#ffffff;
$subMenuitemActiveTextColor:#FFBD45;
$subMenuitemActiveIconTextColor:#FFBD45;

/* Dark Default MenuItem */
$darkMenuitemTextColor:#dee0e3;
$darkMenuitemIconTextColor:#dee0e3;

/* Dark Hover MenuItem */
$darkMenuitemHoverBgColor:rgba(255,255,255,0.4);
$darkMenuitemHoverTextColor:#ffffff;
$darkMenuitemHoverIconTextColor:#ffffff;

/* Dark Active MenuItem */
$darkMenuitemActiveBgColor:#7CB342;
$darkMenuitemActiveTextColor:#ffffff;
$darkMenuitemActiveIconTextColor:#ffffff;
$darksubMenuitemActiveTextColor:#FFBD45;
$darksubMenuitemActiveIconTextColor:#FFBD45;

/**$sidebar_background : #b5b5b5;
$sidebarlogoarea : rgb(255, 255, 255);


.layout-wrapper {
    .layout-sidebar { background-color: $sidebar_background;
        .sidebar-logo { background-color: $sidebarlogoarea; }
    }
}
**/

/***MIXINS***/
@mixin tblliststyle {
    color:  $primary_maincolor ;
    text-decoration: none;
    font-weight: 600;
    padding-bottom: 3px;
}

@mixin btnstyle {
  border:none; outline: none; border-radius: 0px !important;
}

@mixin btnshadow {
    -webkit-border-radius: 1.50px;
    -moz-border-radius: 1.50px;
    border-radius: 1.50px;
    -webkit-box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
}
