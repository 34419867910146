@import '~styles/base/_mixins.scss';

/**Growl Notification edit**/
body .ui-growl { top: auto; bottom: 30px !important; width: 35em;}
body .ui-growl > .ui-growl-item-container.ui-growl-message-error,
body .ui-growl > .ui-growl-item-container.ui-growl-message-success { background-color: rgba(0, 0, 0, 0.7686274509803922); }
.ui-growl .ui-growl-item .ui-growl-image { display:none; }
body .ui-growl .ui-growl-message-error .ui-growl-item .ui-growl-message {
  &::before  { content: url("../../assets/img/if_Error.svg"); @include growlicon; }
}
body .ui-growl .ui-growl-message-success .ui-growl-item .ui-growl-message {
  &::before  { content: url("../../assets/img/checked.svg"); @include growlicon; height: 39px; }
}
.ui-growl-message {margin-left: 3.5em;}
body .ui-growl .pi-times:before {position: relative; font-size: 28px; left: -7px;}
