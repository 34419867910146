.layout-wrapper .layout-main .layout-topbar {
  padding: 0px;
  width: 100%;
  height: 50px;
}
.layout-wrapper .layout-main .layout-breadcrumb {
  min-height: 40px;
  padding-top: 0px;
}
.layout-wrapper .layout-main .layout-breadcrumb ul {
  margin: 4px 0 0 15px;
  li {
    font-size: 13px;
    color: #00000050;
    margin-right: 2px;
    a {
      color: #00000050;
      font-weight: 600;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.layout-wrapper .layout-main .layout-breadcrumb ul li:first-child {
  margin-bottom: -5px;
}

.sidebar-scroll-content {
  background-color: $white;
}

.layout-wrapper {
  .layout-main {
    .layout-content {
      padding-top: 110px;
    }
    .layout-breadcrumb {
      background: #f2f2f2;
      box-shadow: none;
    }
    .layout-topbar {
      .layout-topbar-menu-wrapper {
        .topbar-menu {
          .topbar-badge,
          > li > ul a .topbar-submenuitem-badge {
            background-color: $primary_lightcolor;
          }
          > li.profile-item > a:hover .profile-image-wrapper {
            border-color: $primary_lightcolor;
          }
        }
      }
    }
  }
  .layout-sidebar {
    background-color: $left-nav;
    box-shadow: none;
    .sidebar-logo {
      background-color: $header_backgroundcolor;
      padding-left: 8px;
      .sidebar-anchor {
        border: 2px solid $white;
        background-color: $header_backgroundcolor;
      }
      .mainTransitivLogo {
        margin-left: -35px !important;
      }
      .t-white-logo img {
        border-radius: 5px;
        margin-top: 1px !important;
        margin-left: 35px;
      }
    }
    .nano {
      box-shadow: 8px 0 6px -3px rgba(0, 0, 0, .30);
    }
    .layout-menu {
      li {
        > a {
          color: $gray60;
          i {
            color: $gray60;
          }
        }
        &.active-menuitem {
          > a,
          i {
            color: $primary_maincolor;
            img {
              fill: $primary_maincolor;
            }
          }
        }
        > a:hover {
          color: $primary_maincolor;
          background-color: #1e88e533;
        }
        > a:hover i {
          color: $primary_maincolor;
        }
        > a:hover img {
          fill: $primary_maincolor;
        }
      }
      > li {
        &.active-menuitem {
          > a {
            background-color: #1e88e533;
            color: $primary_maincolor;
            box-shadow: none;
            text-decoration: none;
            i, mat-icon {
              color: $primary_maincolor !important;
            }
          }
        }
      }
      .menuitem-badge {
        background-color: $primary_lightcolor;
      }
      .ng-trigger-children {
        margin-left: 10px;
      }
    }
    &.layout-sidebar-dark .layout-menu > li.active-menuitem > a {
      background-color: $primary_lightcolor;
    }
  }
  .layout-sidebar.layout-sidebar-active {
    .layout-menu {
      li {
        > a {
          padding-left: 10px !important;
          padding-right: 10px !important;
          margin: 0px 5px;
          border-radius: 5px !important;
          display: flex !important;
          align-items: center !important;
          text-decoration: none;
          i {
            float: none !important;
            position: initial !important;
            margin-top: 0px !important;
          }
          span {
            flex: 1;
            max-width: initial !important;
            margin-left: 20px;
          }
        }
      }
    }
  }
}

.layout-wrapper {
  .p-container > div {
    &._r {
      .card {
        width: 18.7%;
      }
    }
  }
}
.layout-wrapper.layout-wrapper-static {
  .p-container > div {
    &._r {
      .card {
        width: 16.5%;
      }
      .tg_accts ._contnt .acct_titl {
        font-size: 13px;
        width: 68%;
      }
      .tg_accts ._contnt .acct_count {
        top: 0px;
        font-size: 13px;
      }
      .tg_accts ._contnt .ui-accordion .ui-accordion-header > a {
        padding: 7px 0px;
      }
      .tg_accts ._contnt .lft {
        width: 75%;
      }
      .tg_accts ._contnt .acct_count {
        padding-right: 1px;
      }
      .tg_accts ._contnt .acct_dets {
        padding-left: 0px;
      }
    }
  }
  .header_card {
    width: 100%;
    left: 0px;
    padding-left: 240px;
  }
  .campaign_summary {
    .campaign_footer {
      left: 0px;
      padding-left: 265px;
    }
  }
  .int_d .headelement {
    left: 0px;
    padding-left: 265px;
  }
}

.layout-wrapper-static {
  #page-header-title {
    width: 98%;
  }
  .layout-sidebar .sidebar-logo .sidebar-anchor {
    background-color: rgba(255, 255, 255, 0.80);
  }
  .layout-menu {
    li {
      > a {
        padding-left: 10px !important;
        padding-right: 10px !important;
        margin: 0px 5px;
        border-radius: 5px !important;
        display: flex !important;
        align-items: center !important;
        text-decoration: none;
        i {
          float: none !important;
          position: initial !important;
          margin-top: 0px !important;
        }
        span {
          flex: 1;
          max-width: initial !important;
          margin-left: 20px;
        }
      }
    }
  }
}

.layout-wrapper:not(.layout-wrapper-static)
  .layout-sidebar:not(.layout-sidebar-active)
  .layout-menu-container
  ul {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  li {
    width: initial;
    a {
      margin: 2.6px 10px;
      position: initial;
      padding: 10px;
      line-height: 0.6;
      text-align: right;
      text-decoration: none;
    }
    &.hasChild {
      a:first-child {
        width: fit-content;
      }
      ul {
        margin-left: 0px !important;
      }
    }
    i {
      position: initial !important;
      float: none !important;
      margin-top: initial !important;
      color: $primary_maincolor_50percent
    }
    mat-icon {
      color: $primary_maincolor_50percent
    }
    span {
      display: none !important;
    }
  }
}

.layout-wrapper .layout-sidebar .layout-menu li>a>.menuitem-text {
  word-break: initial !important;
}

@media screen and (max-width: 700px) {
  .layout-wrapper .layout-main .layout-breadcrumb {
    display: none;
  }
  .layout-wrapper .layout-main .layout-content {
    padding-top: 140px;
  }
}

