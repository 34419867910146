@use 'sass:math';

thead {
  text-align: left;
  font-weight: bold;

  th {
    padding: math.div($goldenEm, 2);
    line-height: math.div($goldenEm, 2);
    vertical-align: bottom;
    border-bottom: 1px solid $gray20;
    border-left: 1px solid $gray20;

    &:first-child {
      border-left: 0;
    }
  }
}

tbody {
  text-align: left;
}

td {
  padding: math.div($goldenEm, 2);
  line-height: 1.42857143;
  border-bottom: 1px solid $gray20;
  border-left: 1px solid $gray20;

  &:first-child {
    border-left: 0;
  }
}

table,
.table {
  background-color: transparent;
  border-spacing: 0;
  border-collapse: collapse;

  &-bordered {
    border: 1px solid $gray20;
  }

  &-stripped {
    tbody > tr:nth-of-type(odd) {
      background-color: $gray10;
    }
  }
}

.table {
  display: table;

  .table-row {
    display: table-row;
  }
  .table-cell {
    display: table-cell;
  }
  .table-thead {
    display: table-header-group;
  }
  .table-tbody {
    display: table-row-group;
  }
  .table-tfoot {
    display: table-footer-group;
  }
  .table-col {
    display: table-column;
  }
  .table-colgroup {
    display: table-column-group;
  }
  .table-caption {
    display: table-caption;
  }
}

table,
.table,
ul,
ol {
  li.title-row {
    height: 50px;
    line-height: 50px;
    font-weight: 600;
    border-bottom: 1px solid $gray10;
  }

  li.list-row {
    padding: $gutter 0;
    line-height: 24px;
    border-top: 1px solid $gray10;

    &:first-child {
      border-top: none;
    }
  }

  li.empty,
  li.loading {
    padding: $gutter $gutter * 2;
  }
  li.edit {
    padding: $gutter 0;
    line-height: normal;

    & > div {
      padding-bottom: math.div($gutter, 2);
    }
  }

  .sortable {
    position: relative;
    white-space: nowrap;
    cursor: pointer;

    span.sort {
      position: absolute;
    }

    i {
      margin: 0 math.div($gutter, 2);
      font-size: 11px;
      color: $gray20;
    }
  }
}
